import * as React from "react";
import { Link } from "react-router-dom";

import { useConfig } from "../store/slices/config";

const Footer = () => {
  const config = useConfig();
  const etherscanUrl =
    config.network == "ropsten"
      ? "https://ropsten.etherscan.io/address/" + config.contractAddress
      : "https://etherscan.io/address/" + config.contractAddress;

  return (
    <div className="Footer">
      <div className="Footer_content">
        <div id="mc_embed_signup">
          <p className="text-gray text-xxs mb-1">Receive Occasional Updates</p>
          <form
            action="https://searanchla.us5.list-manage.com/subscribe/post?u=64e00b1bdf3cfd22c1ddcd0ae&amp;id=7372613280"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate mb-4"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <input
                type="email"
                name="EMAIL"
                className="email small mr-2 mb-0"
                id="mce-EMAIL"
                placeholder="email address"
                required
              />
              <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                <input type="text" name="b_64e00b1bdf3cfd22c1ddcd0ae_7372613280" tabIndex="-1" />
              </div>
              <div className="clear">
                <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button button-md"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="Footer_columns">
          <div className="Footer_group">
            <Link className="Footer_item" to={"/how-it-works"}>
              How It Works
            </Link>
            <Link className="Footer_item" to={"/about"}>
              About
            </Link>
            <Link className="Footer_item" to={"/legal"}>
              Terms &amp; Conditions
            </Link>
            <a className="Footer_item Footer_item--contract" href={etherscanUrl} target="_blank">
              {config.contractAddress}
            </a>
          </div>
          <div className="Footer_group">
            <a className="Footer_item Footer_item--highlight" href="http://twitter.com/hbdnft" target="_blank">
              Twitter
            </a>
            <a className="Footer_item Footer_item--highlight" href="https://discord.gg/j9aGxHaaP4" target="_blank">
              Discord
            </a>
            <a className="Footer_item Footer_item--highlight" href="https://www.instagram.com/hbdnft/" target="_blank">
              Instagram
            </a>
            <a
              className="Footer_item Footer_item--highlight"
              href={`${config.openSeaUrl}/collection/hbdnft`}
              target="_blank"
            >
              OpenSea
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
