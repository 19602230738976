import * as React from "react";
import { Link } from "react-router-dom";

import SelectDayOfMonth from "../components/select-day-of-month";

const SelectMonthOfYear = ({ months, selectedMonth }) => {
  return (
    <div className="SelectMonthOfYear">
      <div className="SelectMonthOfYear_menu">
        {months.map((m, i) => (
          <Link
            className={`SelectMonthOfYear_menu_item ${
              m.name === selectedMonth.name && "SelectMonthOfYear_menu_item--selected"
            }`}
            key={`month${i}`}
            to={`/?month=${m.name}`}
          >
            {m.shortName}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SelectMonthOfYear;
