import * as React from "react";
import "../style/main.scss";
import Page from "../components/page.jsx";
import GlobalNav from "../components/global-nav.jsx";
import Footer from "../components/footer";
import MarketingHero from "../components/marketing-hero";
import SpaceBackground from "../components/space-background";
import HowItWorks1 from "../images/howitworks1.png";
import HowItWorks2 from "../images/howitworks2.png";
import HowItWorks3 from "../images/howitworks3.png";

const HowItWorksPage = () => {
  return (
    <Page className="how-it-works" title="How it Works">
      <SpaceBackground>
        <GlobalNav />
        <MarketingHero>
          <h1>How It Works</h1>
        </MarketingHero>
      </SpaceBackground>
      <div className="page-content">
        <div className="marketing-flex">
          <div className="marketing-flex__text mt-6">
            <p className="text-lg mb-6 bold">RARITY & SPECS</p>
            <p className="text-sm mt-2">
              Each piñata is a completely unique, hand-rendered, 3D model with varying patterns, manes, tails, party
              favors, accessories and other attributes. Out of a possible 47,120,486,400 designs, we minted the optimal
              3,660. And, of course, we’ve hidden a few super-rare versions on various days throughout the year.
            </p>
            <p className="text-sm mt-2">
              All of our piñatas are stored as ERC-721 tokens on the Ethereum blockchain and hosted on IPFS. Find the
              verified contract{" "}
              <a href="https://etherscan.io/address/0xf1C623E1D5f9BE225F8E894C6DD12beA35ef3856" target="_blank">
                here
              </a>
              .
            </p>
            <p className="text-sm mt-2">
              If you’re not digging any of the piñatas available on your day, sorry. Our piñatas are like life. Some
              days are just luckier than others.
            </p>
          </div>
          <div className="marketing-flex__image">
            <img src={HowItWorks1} />
          </div>
        </div>
        <div className="marketing-flex marketing-flex--reverse">
          <div className="marketing-flex__text mt-6">
            <p className="text-lg mb-6 bold">SENDING IT</p>
            <p className="text-sm mt-2">If you’re gifting your piñata, we’ll help with all the details. </p>
            <p className="text-sm mt-2">
              At purchase, if you choose to notify the recipient immediately, they’ll get an email right away. If you
              want to wait for their special day, the email will be sent at 12AM on the day you choose, based on your
              time zone.
            </p>
            <p className="text-sm mt-2">
              And, if they don’t already have one, we’ll walk them through how to set up an Ethereum account and wallet.
            </p>
            <p className="text-sm mt-2">
              Finally, we’ll let you know when they’re ready and help coordinate the gift transfer.
            </p>
            <div className="mt-6">
              <p className="text-lg mb-6 bold">RE-GIFTING IT</p>
              <p className="text-sm mt-2">
                If your paper-handed friend or loved one wants to re-sell their piñata on{" "}
                <a href="https://opensea.io/collection/hbdnft" target="_blank">
                  OpenSea
                </a>
                , great!
              </p>
              <p className="text-sm mt-2">
                Part of the thrill of owning a scarce asset like an NFT piñata is being able to sell it. The more
                piñatas are re-gifted, the more non-crypto folks will feel the thrill of aping in.
              </p>
            </div>
            <div className="mt-6">
              <p className="text-lg mb-6 bold">KEEPING IT</p>
              <p className="text-sm mt-2">
                Or you could just keep it for yourself and not worry about how it’s sent. Then it works like any other
                NFT. And the ~20 million people who share your birthday will have to fight over the remaining 9. Or try
                to buy yours.
              </p>
            </div>
          </div>
          <div className="marketing-flex__image">
            <img src={HowItWorks2} />
          </div>
        </div>
        <div className="marketing-flex">
          <div className="marketing-flex__text mt-6">
            <p className="text-lg mb-6 bold">WHY BUY A PIÑATA?</p>
            <p className="text-sm mt-2">As a gift.</p>
            <p className="text-sm mt-2">As a joke.</p>
            <p className="text-sm mt-2">As an investment.</p>
            <p className="text-sm mt-2">As a profile pic.</p>
            <p className="text-sm mt-2">As an F you to BIG GREETING CARD.</p>
            <p className="text-sm mt-2">
              As an invaluable addition to your growing collection of rare and exotic piñatas from all across the globe.
            </p>
          </div>
          <div className="marketing-flex__image">
            <img src={HowItWorks3} />
          </div>
        </div>
      </div>
    </Page>
  );
};
export default HowItWorksPage;
