import "../style/main.scss";

import * as React from "react";
import { useLocation } from "react-router-dom";

import Footer from "../components/footer.jsx";
import GlobalNav from "../components/global-nav.jsx";
import HighlightBanner from "../components/highlight-banner.jsx";
import MarketingHero from "../components/marketing-hero.jsx";
import Page from "../components/page.jsx";
import SelectMonthOfYear from "../components/select-month-of-year";
import SelectDayOfMonth from "../components/select-day-of-month";
import SpaceBackground from "../components/space-background";

import { useConfig } from "../store/slices/config";
import { months } from "../utils/months.jsx";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const HomePage = () => {
  const monthParam = useQuery().get("month");
  var selectedMonth = months.find(({ name }) => name == monthParam);
  if (!selectedMonth) {
    selectedMonth = months[0];
  }

  const config = useConfig();

  const selectMonthRef = React.useRef();
  const scrollToInventory = () => {
    selectMonthRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  React.useEffect(() => {
    if (monthParam) {
      scrollToInventory();
    }
  });
  const title = monthParam ? monthParam : "Home";

  return (
    <Page title={title} className="HomePage">
      <SpaceBackground isHome={true}>
        <GlobalNav />
        <MarketingHero>
          <div className="marketing-hero-wrapper">
            <h1>
              <span>An NFT for</span> <span>every HBD.</span>
            </h1>
            <p className="text-md mb-2">
              Say “Happy Birthday” to friends and loved ones by gifting them a unique, collectible, hand-rendered
              piñata.
            </p>
            <p className="text-md mb-2">We’ll help coordinate delivery and wallet set-up.</p>
            <p className="text-md mb-2">
              Each piñata is Ξ0.055, and a maximum of 3,660 will ever exist. 366 days. 10 for each.
            </p>
            <p className="text-md mb-2">
              When a day is gone, it’s gone. You’ll have to find another way to say Happy Birthday.
            </p>
            <a className="button button-lg button--dropdown" onClick={scrollToInventory}>
              Find a Birthday
            </a>
          </div>
        </MarketingHero>
      </SpaceBackground>

      {!config.mintingEnabled && <HighlightBanner>HBD piñatas haven't dropped yet. Check back soon!</HighlightBanner>}
      {config.allTokensMinted && (
        <HighlightBanner>
          Unfortunately, all piñatas are sold out. But you can still look around and see what we had!
        </HighlightBanner>
      )}
      <div className="page-content">
        <h1 ref={selectMonthRef}>Find a Birthday</h1>
        <SelectMonthOfYear months={months} selectedMonth={selectedMonth} />
        <SelectDayOfMonth
          name={selectedMonth.name}
          numDays={selectedMonth.numDays}
          monthIndex={selectedMonth.monthIndex}
        />
      </div>
    </Page>
  );
};
export default HomePage;
