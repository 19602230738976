import Torus from "@toruslabs/torus-embed";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Fortmatic from "fortmatic";
import Web3Modal from "web3modal";

export default function getWeb3Modal({ infuraId, network, fortmaticKey }) {
  const providerOptions = {
    // Currently MetaMask will only be displayed if already installed as browser extension
    fortmatic: {
      package: Fortmatic,
      options: {
        key: fortmaticKey,
        network,
      },
    },
  };

  return new Web3Modal({
    cacheProvider: true, // always ask to choose wallet
    network,
    providerOptions,
  });
}
