import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import "../style/main.scss";
import Page from "../components/page.jsx";
import GlobalNav from "../components/global-nav.jsx";
import NftEditionDetails from "../components/nft-edition-details";
import NftColumnView from "../components/nft-column-view";
import MarketingHero from "../components/marketing-hero";
import SpaceBackground from "../components/space-background";

import { useGetOrderQuery } from "../services/nft-hbd";
import { connectWallet, selectSession } from "../store/slices/session";
import { ownerOf, selectTokenOwnership, selectTransfersMap, transferKey, transferNft } from "../store/slices/eth";

const SendNftPage = ({ match }) => {
  const dispatch = useDispatch();
  const { orderId, accessToken } = match.params;
  const { data, error, isLoading } = useGetOrderQuery({ orderId, accessToken });

  let nft_edition, recipient_name;
  if (!isLoading && !error) {
    ({ nft_edition, recipient_name } = data);
  }
  const { accountAddress, isWalletConnected } = useSelector(selectSession);
  const tokenOwnerMap = useSelector(selectTokenOwnership);
  const transfersMap = useSelector(selectTransfersMap);
  const thisTransferKey = transferKey({
    buyerWalletAddress: data?.buyer_wallet_address,
    recipientWalletAddress: data?.recipient_wallet_address,
    tokenId: nft_edition?.token_id,
  });
  const transferStatus = transfersMap[thisTransferKey];

  React.useEffect(() => {
    if (nft_edition) {
      dispatch(ownerOf(nft_edition.token_id));
    }
  }, [nft_edition, accountAddress]);

  var tokenOwnedByAccount;
  if (isWalletConnected && nft_edition && tokenOwnerMap[nft_edition.token_id]) {
    tokenOwnedByAccount = tokenOwnerMap[nft_edition.token_id];
  } else {
    tokenOwnedByAccount = false;
  }

  function doTransfer() {
    if (!accountAddress) {
      dispatch(connectWallet());
      return;
    }
    dispatch(
      transferNft({
        buyerWalletAddress: data.buyer_wallet_address,
        recipientWalletAddress: data.recipient_wallet_address,
        tokenId: nft_edition.token_id,
      })
    );
  }

  function buttonContent() {
    if (!accountAddress) {
      return "Connect Wallet";
    }
    switch (transferStatus) {
      case "sending":
      case "sent":
        return "Waiting For Signature";
      case "transactionHash":
      case "pending":
        return "Transferring...";
      case "receipt":
      case "completed":
        return "Transfer Successful!";

      default:
        return `Send to ${recipient_name}`;
    }
  }

  const isNftOwnedBySender =
    data &&
    tokenOwnedByAccount &&
    tokenOwnedByAccount.status == "fulfilled" &&
    tokenOwnedByAccount.address == accountAddress;
  const isNftOwnedBySenderLoading = data && tokenOwnedByAccount && tokenOwnedByAccount.status == "pending";
  const isTransferred = data?.status == "done" || transferStatus == "completed";

  return (
    <Page title="Send the NFT">
      <SpaceBackground>
        <GlobalNav />
        <MarketingHero>
          {isLoading ? (
            <h1 className="text-xl">Loading...</h1>
          ) : error ? (
            <h1 className="text-xl">Error loading card</h1>
          ) : (
            <>
              <p className="text-sm">{nft_edition.title}</p>
              <h1 className="text-xl">Send to {recipient_name}</h1>
            </>
          )}
        </MarketingHero>
      </SpaceBackground>

      {!isLoading && !error && (
        <NftColumnView nftEdition={nft_edition} hideOwnership={true}>
          {isTransferred ? (
            <>
              <p className="text-lg mb-3 bold">Transfer Complete!</p>
              <p className="text-sm mb-6 bold">
                You’re all set! You transferred {nft_edition.title}. {recipient_name} will get an email letting them
                know. And showing them how much you care.
              </p>

              <NftEditionDetails nftEdition={nft_edition} />
            </>
          ) : !isWalletConnected || isNftOwnedBySender || isNftOwnedBySenderLoading ? (
            <>
              <p className="text-lg mb-3 bold">It's time to send {recipient_name} their HBD NFT.</p>
              <p className="text-sm mb-6 bold">
                {recipient_name} has linked their wallet and is ready to receive {nft_edition.title}. All you have to do
                is whisper a heartfelt Happy Birthday and hit send!
              </p>

              <NftEditionDetails nftEdition={nft_edition} />

              <a href="#" className="button-lg mt-6" onClick={doTransfer}>
                {buttonContent()}
              </a>
            </>
          ) : (
            <>
              <p className="text-lg mb-3 bold">Look like you no longer own this NFT</p>
              <p className="text-sm mb-6 bold">Bummer for {recipient_name}!</p>
            </>
          )}
        </NftColumnView>
      )}
    </Page>
  );
};
export default SendNftPage;
