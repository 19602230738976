import * as React from "react";

const SpaceBackground = ({ children, isHome }) => {
  return (
    <div className={`SpaceBackground SpaceBackground--${isHome ? "home" : "non-home"}`}>
      <div className="pinata-container">
        <div className="floating-pinata pinata-1"></div>
        <div className="floating-pinata pinata-2"></div>
        <div className="floating-pinata pinata-3"></div>
      </div>
      {children}
    </div>
  );
};

export default SpaceBackground;
