import * as React from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";

const ReminderForm = ({ isFetchingReminder, submitAction, apiErrors }) => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isValid, submitCount, isSubmitting },
  } = useForm();

  const send_later = watch("send_later");

  React.useEffect(() => {
    if (apiErrors) {
      console.log(apiErrors);
      Object.keys(apiErrors).forEach((fieldName) => {
        setError(fieldName, { type: "manual", message: apiErrors[fieldName].join(", ") });
      });
    }
  }, [apiErrors]);

  const requiredMessage = "is required";

  const showCheckErrorsMessage = errors && Object.keys(errors).length > 0;

  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <fieldset>
        <input placeholder="Your Name" {...register("buyer_name", { required: requiredMessage })} />
        {errors.buyer_name && <span className="form-error">{errors.buyer_name.message}</span>}
        <input placeholder="Your Email" {...register("buyer_email", { required: requiredMessage })} />
        {errors.buyer_email && <span className="form-error">{errors.buyer_email.message}</span>}
        <label>
          <input type="checkbox" {...register("subscribe_buyer")} />{" "}
          <span className="text-md pl-2"> Receive occasional updates from us!</span>
        </label>
      </fieldset>

      <fieldset>
        <input placeholder="Recipient Name" {...register("recipient_name", { required: requiredMessage })} />
        {errors.recipient_name && <span className="form-error">{errors.recipient_name.message}</span>}
        <input placeholder="Recipient Email" {...register("recipient_email", { required: requiredMessage })} />
        {errors.recipient_email && <span className="form-error">{errors.recipient_email.message}</span>}
      </fieldset>

      <fieldset>
        <label>
          <input type="checkbox" {...register("send_later")} />{" "}
          <span className="text-md pl-2"> Send on their birthday</span>
        </label>
      </fieldset>

      {send_later && (
        <fieldset>
          <div className="grid">
            <div className="col-6 pr-2">
              <input
                placeholder="MM"
                {...register("delivery_month", {
                  required: requiredMessage,
                  max: { value: 12, message: "Must be between 1-12" },
                  min: { value: 1, message: "Must be between 1-12" },
                })}
              />
              {errors.delivery_month && <span className="form-error">{errors.delivery_month.message}</span>}
            </div>
            <div className="col-6 pl-2">
              <input
                placeholder="DD"
                {...register("delivery_day", {
                  required: requiredMessage,
                  max: { value: 31, message: "Must be between 1-31" },
                  min: { value: 1, message: "Must be between 1-31" },
                })}
              />
              {errors.delivery_day && <span className="form-error">{errors.delivery_day.message}</span>}
            </div>
          </div>
        </fieldset>
      )}
      <fieldset>
        <textarea placeholder="Birthday Message" {...register("message", { required: requiredMessage })} />
        {errors.message && <span className="form-error">{errors.message.message}</span>}
      </fieldset>

      <fieldset>
        {isSubmitting || isFetchingReminder ? (
          <a className="button-lg mt-4 test">
            <Loader type="ThreeDots" color="#FFF" height={30} width={100} />
          </a>
        ) : showCheckErrorsMessage ? (
          <div className="button-lg button-light button--disabled">Fix Errors</div>
        ) : (
          <input type="submit" className="button-lg" value={send_later ? "Schedule Email" : "Send Email"} />
        )}
      </fieldset>
    </form>
  );
};

export default ReminderForm;
