import { configureStore } from "@reduxjs/toolkit";
import persistState from "redux-localstorage";

import sessionReducer from "./slices/session";
import ethReducer from "./slices/eth";
import configReducer from "./slices/config";

import { nftHbdApi } from "../services/nft-hbd";

export const store = configureStore({
  reducer: {
    [nftHbdApi.reducerPath]: nftHbdApi.reducer,
    session: sessionReducer,
    eth: ethReducer,
    config: configReducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(nftHbdApi.middleware),

  enhancers: [persistState("session", { key: "hbdnft" })],
});
