const useJokeByDay = ({ month, day }) => {
  return JOKES_BY_DAY[`${month}/${day}`];
};

export default useJokeByDay;

const JOKES_BY_DAY = {
  "1/1": "Like the Gregorians always said, \"if you ain't first you're last.\" Congrats, you're first.",
  "1/2": "You're second! Which some consider last. Sorry.",
  "1/3": "The bronze medal in the Olympic sport of calendar dates. At least you're on the podium.",
  "1/4": "1/4 the money. Two for the show. Three to get ready now go go go.",
  "1/5": "Are you or do you know a charming Capricorn? Lucky you.",
  "1/6": "Apparently, you're most compatible with Virgos. Take that for whatever it's worth.",
  "1/7": "Halfway through the first fortnight of the year. Gotta be worth something, right?",
  "1/8": "Happy Earth's Rotation Day! Seriously, THANK YOU for rotating, Earth.",
  "1/9": "Happy Non-Resident India Day to all Indians residing in India and not residing in India!",
  "1/10": "Ten out of Ten! Highly recommend. Would be born on this day again.",
  "1/11": "Either 1/11 or 11/1 depending on where you are. Either way, too many 1's.",
  "1/12": "The day Caesar crossed the Rubicon making a civil war between him and Pompey inevitable. DRAMA!",
  "1/13":
    "Woohoo. It's the day Pope Honorius II granted a papal sanction to the military order known as the Knights Templar.",
  "1/14": "Only 351 days remaining in the year. A little less than that to finish your Christmas shopping.",
  "1/15": "It's International Fetish Day! Also, Pitbull's birthday! Coincidence? Yeah, probably.",
  "1/16":
    "Happy Birthday Lin Manuel Miranda. If you see this, please write a Grammy-winning song about these piñatas. TY.",
  "1/17": "Being a Capricorn born on January 17th, you are well known for your intelligence, discipline and loyalty.",
  "1/18":
    "The Capricorn-Aquarius Cusp empowers you to be systematic and meticulous. Great for a serial killer, I guess? ;)",
  "1/19":
    "Dolly Parton was born on this day. So, if you're born on this day, the whole world is basically jealous of you.",
  "1/20": "Penguin Awareness Day? Who's in charge of making days? I feel like they've lost the plot.",
  "1/21": "Happy Squirrel Appreciation Day! Yes, this is real. Luckily, squirrels are easy to appreciate.",
  "1/22": "Fun Fact: No one has ever been born on this day.  (ed. note: might not be 100% true)",
  "1/23": "1/23. Easy as ABC, Do Re Mi, Baby you and me, girl.",
  "1/24": "On this day in [year], [person] was born and [thing] happened. Imagine that!",
  "1/25": "On this day, the first arcade game was patented. Quarters everywhere rejoiced.",
  "1/26": "It's not Aquari-you. It's Aquarius. I thought you knew that! Please take me back, Linda!",
  "1/27": "If it's January 27th in Hawaii, it's probably already January 28th in Tonga.",
  "1/28": "If it's January 28th in Hawaii, it's probably already January 29th in Tonga.",
  "1/29": "If you were born on January 29th, Happy Birthday! If not, MOVE IT ALONG! (jk)",
  "1/30": "According to history, a lot of just truly awful things happened on this day. Sorry.",
  "1/31": "You're first (month of year) AND last (day of month). How, um, lucky?",
  "2/1": "So begins the shortest, coldest, most bleak month of the year.",
  "2/2": "Isn't it incredible how quickly February seems to go by, but then March still feels like winter?",
  "2/3": "February 3rd is the birthdate of over 30 Academy Award winning actors (don't look it up).",
  "2/4": "Feb John, Feb Paul, Feb George and Feb Ringo. The Feb 4.",
  "2/5": 'February 5th. Or, as the kids call it, "February 5th"',
  "2/6": "If you Google what is special about February 6th, I'm sure you'll find at least one thing.",
  "2/7":
    "Hollywood celebs born on February 7th include Ashton Kutcher. Crypto celebs born on February 7th also include Ashton Kutcher.",
  "2/8": 'Motorhead\'s "Ace of Spades" was loosely based on the "Eighth of Febs"',
  "2/9": "Does anyone actually annunciate the first 'R' in February?",
  "2/10": "February 10th is National Umbrella Day. Which is why it has never not rained on February 10th.",
  "2/11": "Thomas Edison was the first and only person born on February 11th. Which is somewhat true.",
  "2/12": "February 12th marks the one month anniversary of January 12th.",
  "2/13": "February 13th marks the one month anniversary of January 13th.",
  "2/14": "February 14th is the hardest day in the world to get a decent dinner reservation",
  "2/15":
    "The ides of February isn't a thing, but if it was, it would be today. Still, no need to beware the ides of February.",
  "2/16": "If you were dumped on Valentine's Day, today is the day you can start to process your feelings.",
  "2/17": "Today is Michael Jordan's birthday. And Michael Jordan doppelganger Ed Sheeran's birthday.",
  "2/18": "On February 18th, 1865 it probably snowed somewhere in the world.",
  "2/19": "February 19th is the most auspicious day in the calendar year. Trust us on this one.",
  "2/20": "February 20th is the 51st day in the Gregorian calendar. Which makes it exactly 51/365ths through the year.",
  "2/21":
    "The most famous person born on February 21st is more famous than the most famous person born on February 22nd.",
  "2/22":
    "The most famous person born on February 22nd is less famous than the most famous person born on February 21st.",
  "2/23": "In 1987, February 23rd was a Monday.",
  "2/24": "In 1987, February 24th was a Tuesday.",
  "2/25": "If it's the coldest day of the year, there's a 47% chance it's February 25th.",
  "2/26": 'It\'s right around this day when people start to say, "how is it still February?"',
  "2/27": "It's right around this day when people start to say, \"wow, February's almost over. That was quick.\"",
  "2/28": "Sometimes, this is the last day of the month. Other times, it's not. You win some, you lose some.",
  "2/29": "This is quite literally the only day that doesn't happen once a year. Weird.",
  "3/1": "March 1st. The first day to truly appreciate how simultaneously short and long February is.",
  "3/2": "March 2? March to nowhere.",
  "3/3": "The third day of the third month. Three for three. Three's a crowd. Three something something...",
  "3/4": "March forth, young soldier.",
  "3/5": "Happy Cinco de Marzo. A holiday in celebration of nothing that we just made up!",
  "3/6": "Happy Birthday, Josh Hart! One of these is for you. (Guys, save one for Josh Hart.)",
  "3/7": "Today is Peter Saaarsgaaaard's birthday. You can remember it's the 7th because his name has 7 a's.",
  "3/8": "March 8th is International Women's Day, when brands from all across the globe pander to women.",
  "3/9": "In leap years, March 9th is the 69th day of the year. No further questions.",
  "3/10": "March 10 is also the first thing you're supposed to do if you're in a duel.",
  "3/11": "If you're in a duel and you March 11, you're probably going to lose to the guy who marches 10.",
  "3/12":
    "According to the first page of search results, a Pisces born on this day brings creativity and grace to everything they do.",
  "3/13": "Out of everyone born on this day, only William H. Macy acted in both Fargo and Boogie Nights.",
  "3/14": "3.14159265358979323846264338327950288419716939937510582097494459230781...",
  "3/15": 'The only day out of the entire calendar year that people tell you to "beware"',
  "3/16":
    'The day after the ides of March. Probably when everyone in ancient Rome was like, "yoooo, yesterday was crazy."',
  "3/17": "The day we celebrate the Patron Saint of Ireland by spending green money on NFTs.",
  "3/18": "The day we celebrate the Patron Saint of appreciating NFTs.",
  "3/19": "The day before the spring equinox. The busiest spring equinox shopping day of the year.",
  "3/20": "The spring equinox. Either the best or second best equinox of the year, according to everyone.",
  "3/21": 'Today is "Common Courtesy Day". So, thank you for being here. And you\'re welcome to buy a piñata.',
  "3/22": "March 22nd is the National Emo day of mourning for some reason.",
  "3/23": "On this day in 1840, the first detailed photo of the moon was taken. It doesn't really hold up.",
  "3/24": "My mom was born on this day. Happy Birthday, mom.",
  "3/25": "On this day in 2378, Smeglorb will inherit the interplanetary lands known as Dyvian-238",
  "3/26": "On this day in 2378, the citizens of Dyvian-238 will declare war on Smeglorb.",
  "3/27": "On this day in 2378, Dyvian-238 will be carbonized by Smeglorbian laser blasters.",
  "3/28":
    "On this day in 2379, the galaxy of Centaurus A will honor the victims of Three Day War between Smeglorb and Dyvian-238.",
  "3/29": "A date that perpetually feels like a Monday. Even when it falls on a Friday.",
  "3/30": "When writing 366 distinct product description pages, it's important to triple-check for any tiny misplake.",
  "3/31": "It's still March, but April's so close, you can practically taste the allergens in your sinuses.",
  "4/1": "On this day, trust no one. Everyone's out to get you.",
  "4/2": "You fell for it yesterday, didn't you. We even warned you! You fool.",
  "4/3": 'Today is the unofficial start of "why isn\'t it hot yet?" season.',
  "4/4": 'Day 2 of "why isn\'t it hot yet?" season.',
  "4/5": "Never forget April drives people crazy by not truly being springtime.",
  "4/6": "The only calendar day in which the same exact number of famous people were born and died.",
  "4/7": "You can probably hear the baby birds chirping outside your window. That's nice, isn't it?",
  "4/8":
    "April remains the only month on the calendar that shares its name with a Teenage Mutant Ninja Turtle character.",
  "4/9": "April 9th is also a Danish war film that currently has 0 reviews on Rotten Tomatoes. 6.6/10 on IMDb.",
  "4/10":
    "The 100th day of the year! (Not counting leap days. [But most people don't count leap days. {Poor leap babies}]).",
  "4/11":
    "The second most beautiful person born on this day is Alessandra Ambrosio. The first is you. If you were born on this day, that is.",
  "4/12": "Happy Birthday, Mark! (We don't know a Mark with this birthday, we're just assuming there is one.)",
  "4/13": "Today in 1055 Bishop Gebhard van Eichstattt was named Pope Victor II. Obviously, LOL.",
  "4/14":
    "In 1912, the RMS Titanic hit an iceberg. Like 80 years later, a bunch of tweenage girls cried about Leo not fitting on that door.",
  "4/15":
    "This is the first day of the Aries-Taurus cusp. If you know what that is, you probably already know what that means.",
  "4/16": "Sometimes, Easter falls on this day. Other times, it does not.",
  "4/17": "Sometimes, Easter falls on this day. Other times, it does not.",
  "4/18": "Sometimes, Easter falls on this day. Other times, it does not.",
  "4/19":
    "The historical events that occured on this day throughout history are too numerous to name. So we won't even try.",
  "4/20": "A very happy 4/20 to all those who celebrate.",
  "4/21": "On this day, in 2022, it will be, or is, or was, a Thursday.",
  "4/22": "On this day, in 2022, it will be, or is, or was, a Friday.",
  "4/23":
    "At some point in history, someone who was born on this day also died on this day. We don't know who, but it has to be true.",
  "4/24": 'This day marks the 24th day in the month known as "April".',
  "4/25": 'According to the cinematic masterpiece Miss Congeniality, today is "the perfect date."',
  "4/26": "On this day in 1992, there was a riot on the streets, tell me, where were you?",
  "4/27":
    'To find out which famous people were born on this day, all you have to do is type in "April 27" in any search engine.',
  "4/28":
    "On this day in 1789, there was a Mutiny on the Bounty. In 1962, it was made into a film. In 1986, it was a Beastie Boys lyric.",
  "4/29": "Be half as badass as Joan of Arc, who was born on this day in 1429.",
  "4/30": "Be half as prolific as Travis Scott, who was born on this day in 1991.",
  "5/1":
    "May Day! AKA International Workers Day. AKA what you shout from a sinking ship. International workers on sinking ships? Today is your day.",
  "5/2": "If you're celebrating your birthday today, I may too. See what we did there?",
  "5/3": "May the Third be with you. We did that a day early, didn't we?",
  "5/4": "Happy Star Wars Day, ya silly Trekkies! ;)",
  "5/5": "Cinco de Mayo is an incredibly easy excuse to drink margaritas and eat tacos.",
  "5/6": "We should also drink margaritas and eat tacos on Seis de Mayo.",
  "5/7":
    "Today in 1544, the Burning of Edinburgh set off the Rough Wooing. Easily the sexiest name you could give a series of battles.",
  "5/8":
    "Happy Birthday Luis Enrique (sexy footballer) and Enrique Iglesias (sexy singer) and Luis Iglesias (fictional person).",
  "5/9": "Victory Day in Russia. Good job, Russia. We're all very proud of you.",
  "5/10": "Today is Bono's birthday. He's probably the type of guy that would want you to make a big deal of it.",
  "5/11":
    "On this day in 330, Constantine I dedicated Byzantium as the new capital of the Eastern Roman Empire. We don't make city names like Byzantium anymore.",
  "5/12":
    "On this day, Richard I of England married Berengaria of Navarre in Cyprus. Celebrity couple name: Berchard of Navland.",
  "5/13": "On some years, this date falls on Friday the 13th. SpoOOooOOooky.",
  "5/14": "Today marks the three month anniversary of February 14th.",
  "5/15":
    "On this day in 1602, Cape Cod was discovered by Bartholomew Gosnold. Even though it was probably just there the whole time.",
  "5/16": "A great day to get married, if you're looking for one. Flowers will be in full bloom.",
  "5/17": "By this point in the northern hemisphere spring has well and truly sprung.",
  "5/18": "A big Happy Birthday to Pope John Paul II and Nicholas II of Russia. Two of the top twos of all time.",
  "5/19": "Today marks the one week anniversary of May 12th. Celebrate accordingly.",
  "5/20":
    "It's Cher's birthday today. So, if anyone's got her number, shoot her a Happy Birthday text. She'll appreciate it.",
  "5/21": "It's World Meditation Day. So just stop, kick your feet up and ruminate on that for a bit. Om.",
  "5/22": "On this day in history, the world's most something happened somewhere to someone. Probably.",
  "5/23": "It's Gemini season. You know what that means? No? Neither do we.",
  "5/24": "To all the Canadians out there, enjoy drinking your high-proof beers on your patios and in your cottages.",
  "5/25": "On this day, 135 years from now, something incredible will happen. None of us will be around to enjoy it.",
  "5/26":
    "On this day, 3 years from now, all of your wildest dreams will come true. But so will all of your eerie premonitions.",
  "5/27":
    "Apparently, today is Joseph Alberic Twisleton-Wykeham-Fiennes's birthday. Happy Birthday, Joseph Alberic Twisleton-Wykeham-Fiennes!",
  "5/28": "On this day in 640, Severinus begins his reign as Catholic Pope. Big day for him, we're guessing.",
  "5/29":
    "It's Biscuit Day! Unclear if that's an English biscuit or an American biscuit or a rogue biscuit of unknown origin. Either way, probably delicious.",
  "5/30":
    "On this day in 1584, Earl Adolf of Nieuwenaar and Meurs becomes viceroy of Gelderland. Big promotion for him. Well deserved.",
  "5/31": "Many people don't realize May has 31 days. But it does. This day just goes to prove it.",
  "6/1": "If there was only one month, today would be the 153rd of January. Instead, it's June 1st.",
  "6/2": "Every year, on this day, the world celebrates June 2nd.",
  "6/3": "Here's an incredibly mundane fact: This year, June 3rd was a Thursday.",
  "6/4": "Chronologically, the 4th day in the month of June.",
  "6/5":
    "On this day in history, a battle undoubtedly unfolded somewhere in the world. We're a violent species, aren't we?",
  "6/6": "It's the National Day of Sweden! Let's all celebrate with a Volvo ride to Ikea.",
  "6/7": "Look up at the moon and swoon it's the seventh of June.",
  "6/8": "June 8th is World Oceans Day. I bet the oceans don't even know that.",
  "6/9": "There is a film called June 9 with a 35% audience rating on Rotten Tomatoes.",
  "6/10": "If you were born on this day, DM us, we'd love to hear from you.",
  "6/11": "Odd, but true. Every celebrity whose birthday falls on June 11th has brown hair.",
  "6/12":
    "Historically, world events that have taken place on June 12th have been both good and bad and happy and sad.",
  "6/13":
    "June 13th is the birthday of both Ashley Olsen AND Mary-Kate Olsen, who happen to share a last name AND a birthday.",
  "6/14": "As of today, there are 200 days left in the calendar year. Time is flying by.",
  "6/15": "Today is the 166th day of the year, and there 199 days remaining. Both very aesthetically pleasing numbers.",
  "6/16":
    "Happy Birthday Tupac! Everyone pour one out for Tupac. Unless he's alive on a remote island somewhere. In which case, Happy Birthday Tupac!",
  "6/17":
    "According to their zodiac chart, people born on this day are smart, enthusiastic, adaptable and intelligent. Lucky them.",
  "6/18": "Happy Birthday Paul McCartney, lead singer and guitarist of Wings.",
  "6/19":
    "Today in 1999, the British Royal family celebrated the wedding of Prince Edward and Sophie Rhys-Jones. Everyone else just kept on living their lives.",
  "6/20": "Today is Tall Girl Appreciation Day. But isn't that also every other day?",
  "6/21":
    "Today is the summer solstice in the northern hemisphere, but the winter solstice in the southern hemisphere. Get it together, guys! Which one is it?",
  "6/22":
    "Today is Vijay's Birthday, the Meryl Streep of Tamil cinema. And it's also Meryl Streep's birthday, the Vijay of Hollywood cinema.",
  "6/23": "If you know someone born on June 23rd, today is the day you should wish them a Happy Birthday.",
  "6/24":
    "It's Messi's birthday today. A funny birthday gift to get him would be a soccer ball. (Because he most likely already has that).",
  "6/25": "If you wanted to get Messi a birthday present, you missed it. His birthday was yesterday.",
  "6/26":
    "In this day in 1483, RIchard III usurped himself to the English throne. In 2007, Lil Wayne sizzurped himself in his kitchen.",
  "6/27": "Out of the Gregorian calendar's traditional 365 days, today is the 178th.",
  "6/28": "Mike Tyson's birthday is in two days. So, if you're looking for a gift for him, you're running out of time.",
  "6/29": "Tomorrow is Mike Tyson's birthday. Today isn't.",
  "6/30": "Happy Birthday, Mike Tyson! Someone buy Mike Tyson a piñata. We think he'd love one.",
  "7/1": "Today marks the first day of the second half of the year.",
  "7/2": "Today marks the second day of the second half of the year.",
  "7/3": "If your name was July, and your dad's name was July and his dad's name was July, you'd be July the third.",
  "7/4":
    "If your name was July, and your dad's name was July and his dad's name was July, and his dad's name was July, you'd be July the fourth.",
  "7/5": "It's Shohei Otani's birthday today. Someone hit a ball to the moon in his honor.",
  "7/6": "It's International Kissing Day. Pucker up, buttercups.",
  "7/7": "Today is 7/7. And there are 177 days remaining in the year. Sometimes math is just so beautiful.",
  "7/8": "Today is the two-month anniversary of May 8th. What are we all doing to celebrate?",
  "7/9": "It's Tom Hanks' Birthday today! It's also the opposite of Tom Hanks, OJ Simpson's birthday today.",
  "7/10": "Apparently today is Don't Step on a Bee Day. In honor of Don't Step on a Bee Day, don't step on a bee.",
  "7/11":
    "On this day in 1405, Chinese fleet commander Zheng He set sail on his first major expedition to the Spice Islands. Before which, his chicken was v bland.",
  "7/12":
    "The most famous person born on this day was Henry David Thoreau. But I think Brock Lesnar might have something to say about that.",
  "7/13": "The guy who played Professor X and the guy who played Han Solo were both born on this day.",
  "7/14": "In honor of Bastille Day, let's all overthrow a tyrannical government. You go first.",
  "7/15": "Damian Lillard was born on this day. Which makes it Dame Time all Dame Day.",
  "7/16": "Today is Guinea Pig Appreciation Day. A holiday made up by Guinea Pigs.",
  "7/17":
    "Happy Birthday to the world's leading NFT collector, Angela Merkel. (We're just assuming she is based on nothing.)",
  "7/18":
    "Of all the things that have happened on this day throughout history, more inconsequential things have happened than consequential.",
  "7/19":
    "Today is the birthday of Hollywood actor Benedrink Cundumbatch. Bennifer Cumberland. Bendoffer Company. Or something like that.",
  "7/20": "On July 20, 1969, Apollo 11 landed on the moon. Or did it? It did. But did it really?! (yes.)",
  "7/21":
    "On July 21, 1969, the conspiracy theory that we didn't actually land on the moon was taken up by early adopters.",
  "7/22": "Happy Birthday to The Battle of Dornach. Hopefully you're celebrating with cannon fire and human misery.",
  "7/23":
    "Happy Birthdays to the man who played Harry Potter in Harry Potter, Daniel Radcliff, and the man who played Woody Harrelson in life, Woody Harrelson.",
  "7/24":
    "On this day, at some point in history, someone had their best day ever. And someone had their worst. And for some, it was just Tuesday.",
  "7/25":
    "Today, many many many years ago, Constantine I is announced as the new Roman Emperor by his brown-nosing troops.",
  "7/26": "Happy Birthday to Jason Statham and no one else. Only Jason Statham matters today.",
  "7/27": "In America, it's National Scotch Day. In Scotland, it is not National Bourbon Day. Curious.",
  "7/28":
    "Today was the official start of WWI. So everything that's still currently going on in geopolitics all comes down to this day. How exciting!",
  "7/29": "On this day, people really begin to lament the end of summer. Despite it still being July.",
  "7/30":
    "On this day, someone recorded the most mosquito bites of all time from ankle to knee. Not saying who. Just saying it must've happened.",
  "7/31": "The 31st and last day of July. Or, if you prefer, the -1st of August.",
  "8/1":
    "Today is Swiss National Day, when we celebrate Roger Federer, cows, cheese, watches and chocolate. In that exact order.",
  "8/2": 'Little known fact: August 2nd prefers to go by the more formal "Second of August".',
  "8/3": "August 3rd is the 3rd most important day in August. Oddly, behind the 11th and the 4th.",
  "8/4":
    "On this day in history, at the Battle of Evesham, English prince Edward beats Simon de Montfort the younger. Simon de Montfort the older had no comment.",
  "8/5":
    "In the northern hemisphere, days are long and hot and boring. In the southern hemisphere, everything spins backwards.",
  "8/6":
    "Andy Warhol and M. Night Shyamalan celebrate their birthdays today. (Not together, as they currently reside in different planes of existence.)",
  "8/7": "If August was a hot new album charging up the charts, the 7th would be the hit single.",
  "8/8":
    "August 8th is one of the luckiest days in August, which puts it in contention for luckiest of the calendar year.",
  "8/9": "What's so special about August 9th, asks the writer of the sentence. Anyone?",
  "8/10":
    "Today is most likely one of the daily leaders in thunderstorms throughout the year. (Having done zero research on the subject.)",
  "8/11": "Today is Hulk Hogan's birthday and Joe Rogan's birthday but not Lara Logan's birthday.",
  "8/12": "Celebrate! It's the beginning of grouse shooting season! Unless you're a grouse. In which case, LOOGOUT!",
  "8/13": "If August was an American building, it might not have a 13th. But it isn't, so it does.",
  "8/14": "Happy Birthday Mila Kunis! Do you need a writer for Stoner Cats? If so, I know a me.",
  "8/15":
    "Happy Birthday Ben Affleck and Jennifer Lawrence, who, if they were together, would be called Bennifer. Did I just invent that? OMG, so clever.",
  "8/16":
    "On this day, Nicephorus II Phocas was crowned emperor of the Byzantine Empire in Hagia Sophia by the patriarch Polyeuctus. But we don't have to tell you that.",
  "8/17":
    "On this day in 1835, Solymon Merrick of Springfield, Massachusetts patents the first wrench. How was there no wrench before then?",
  "8/18": "Happy Birthday G-Dragon. You are beautiful and I love you.",
  "8/19":
    "On this day, Enea Silvio Piccolomini was elected pope as Pius II. Sad he had to give up Piccolomini. That's an all-time top surname.",
  "8/20": "Happy Birthday Amy Adams, Ben Barnes, and not the C+C Music Factory.",
  "8/21": "As a Leo born on August 21st, you are known for your being active, ambitious and honest. Lucky you.",
  "8/22": "You're a Leo. And we know they say not to, but you should marry a Libra.",
  "8/23": "In ancient astrology, this is a cusp day between Virgo and Leo. Sup, cusp fam?",
  "8/24": "Vesuvius erupted on this day. Which was a super effed up thing of the earth to do, IMO.",
  "8/25":
    "August 25th is the 237th day of the year. 238th during leap years. And 239th if you're just bad at counting.",
  "8/26": "August 26th is the debut mixtape by American rapper Post Malone. And yes, he wrote it about you.",
  "8/27": "TikTok is obsessed with August 27th. Why? No one knows/cares.",
  "8/28": "Happy Birthday to Armie Hammer, owner of the most simultaneously silly/tough names in Hollywood.",
  "8/29": "Chronologically, today ranks 29th out of 31 in the month of August.",
  "8/30": "If August had 30 days, today would be the last day in August. As of now it doesn't, so it's not.",
  "8/31": "Today is the last day of August. Celebrate, commemorate or commiserate as you see fit.",
  "9/1": "Kids all across the globe are starting to head back to school. Finally.",
  "9/2": "The long-awaited (one day) sequel to September 1st, it's September 2nd.",
  "9/3": "The world's first cannery opened on this day. Celebrate by using a can. Any kind of can. Your choice!",
  "9/4": "It's Beyonce's Birthday. Yet, somehow, not an international holiday. That's weird.",
  "9/5": "It's Virgo season. Go on with your humble, self-effacing and practical selves, Virgos.",
  "9/6": "Today is Idris Elba's birthday. So, as far as we're concerned, it doesn't need to be anything else.",
  "9/7":
    "A solar eclipse on this date might mark the birth of legendary Heracles at Thebes, Greece. But since it was 1251 BC, that's probably like 8% accurate.",
  "9/8": "If you were born on September 8th, your lucky color is green. And your lucky number is 2,651,353,093.",
  "9/9": "Tonight we're gonna party like it's 9/9. Because it is.",
  "9/10": "People born on this day are easy-going, happy-go-lucky and have many other hyphenated traits.",
  "9/11": "Who doesn't love a Virgo? Geminis, that's who.",
  "9/12":
    "On this day in 1933, Leó Szilárd, waiting at a red light, conceives idea of a nuclear chain reaction. Sounds like a lie.",
  "9/13": "According to math, Friday the 13th is just as common as Saturday the 23rd or Wednesday the 2nd.",
  "9/14":
    'On this day, Handel finished his "Messiah" oratorio after working on it non-stop for 23 days. And it was a certified banger.',
  "9/15":
    "Happy Birthday Prince Harry! And Happy Birthday Tom Hardy, who honestly would've been a much more impressive Prince.",
  "9/16":
    "This day marks 100 days until Christmas. No pressure. Plenty of time to go. But you might want to start brainstorming.",
  "9/17":
    "9/17 is how today's date is written in America. 917 is also an area code for New York, which is in America. You can't make this stuff up, folks.",
  "9/18": "Happy September 18th, everyone. It's a good day, today. One of the best.",
  "9/19": "Today is the one year anniversary of September 19th of last year. Let that sink in.",
  "9/20": "Today is the one day anniversary of September 19th. Let that sink in.",
  "9/21": "Do you remember the 21st night of September? Probably not as well as Earth, Wind and Fire do.",
  "9/22": "Today was the first day that Earth, Wind and Fire started to remember the 21st night of September.",
  "9/23": "Happy Birthday to the Boss, Bruce Springsteen and the Boss' boss, Julio Iglesias.",
  "9/24": "In 99 days, it will be January 1st again. In 100, it will be January 2nd. And so on.",
  "9/25":
    "Happy birthday to fictional Jedi Mark Hamill and real-life mystical knight trained to guard peace and justice in the Universe, Will Smith.",
  "9/26": "How nice is the weather out today? Odds are, probably pretty beautiful.",
  "9/27": "If you're a sports fan, it's the most wonderful time of the year.",
  "9/28": "September 28th is not a holiday. And don't let anyone try and convince you otherwise.",
  "9/29": "If Kevin Durant ever played Halsey 1 on 1, they should do it today, since it's both of their birthdays.",
  "9/30": "Today is the 30th and last day of September. Good riddance you gorgeous month, you.",
  "10/1": "October to the main stage. October to the main stage.",
  "10/2":
    "If you think of a good Halloween costume today, write it down. Because you will forget by Halloween and then you'll be scrambling.",
  "10/3": "Libra Season. Halloween Season. It's a good time to be alive/born.",
  "10/4": "There are 88 days left in the year. And October 3rd is not one of them.",
  "10/5":
    "It's Kate Winslet's Birthday today. The world's most famous Winslet. And Daniel Baldwin's Birthday. The world's 3rd or 4th most famous Baldwin.",
  "10/6": "Happy First Time Michael Jordan Retired Day!",
  "10/7":
    "Since we assume he combs the internet each morning to search every mention of his name, Happy Birthday Vladimir Putin!",
  "10/8": "Happy Birthday to our two favorite Martians, Matt Damon and Bruno Mars!",
  "10/9":
    "Don't forget, there are only six days left before October 15th. Or forget. Doesn't really matter either way.",
  "10/10": 'The tenth best day in October, in terms of "days closest to September".',
  "10/11": "People born on this day are known for their thoughtfulness and seriousness. Jeez, loosen up, guys.",
  "10/12": "Happy Ada Lovelace day! Yep, the world's first computer programmer.",
  "10/13":
    "Happy Birthday, Margaret Thatcher and the person we'd most like to see portray Margaret Thatcher in a biopic, Sacha Baron Cohen!",
  "10/14":
    "Today in history, Robert the Bruce of Scotland defeats King Edward II of England at the Battle of Old Byland, forcing Edward to accept Scotland's independence. Obvs.",
  "10/15": "Happy birthday to Friedrich Nietzsche! Go celebrate by taking a good long stare into the abyss.",
  "10/16": "Happy Birthdays to the holy triumvirate: Naomi Osaka, John Mayer and Oscar Wilde.",
  "10/17": "Two weeks to Halloween. SpOoKy SZN is officially upon us.",
  "10/18": "Today is the one month anniversary of September 18th and the 2-month anniversary of the month before that.",
  "10/19": "Today's saint is Saint Laura of Cordoba. If you didn't already know that, now you do.",
  "10/20": "Happy Birthday Snoop Dogg! And Happy Birthday white Snoop Dogg, John Krasinski.",
  "10/21": "On this day in 1520, Magellan entered Magellan's strait. What a coincidence.",
  "10/22": "Happy Birthday to Jeff Goldbum, Bob Odenkirk and Christopher Lloyd. Truly a GOAT day in birthdays.",
  "10/23": "Happy Birthday to probably at least one guy named Chris. Just playing the odds here.",
  "10/24": "Happy Birthday to at least one girl named Christina. Maybe even more than one.",
  "10/25": "Scorpio season is really taking off now. Hide your wives. Hide your kids.",
  "10/26": "People born on this day are ambitious, independent and intuitive. And feisty as all hell.",
  "10/27":
    "Sylvia Plath, whose best-known works examine themes of alienation, death, and self-destruction was born today. Hooray!",
  "10/28":
    "Bill Gates, the inventor of the fictional microchip that somehow monitors you everywhere you go even though your phone already does that, was born today.",
  "10/29": "Bob Ross was born today. Which means Bob Ross is a Scorpio. That seems wrong.",
  "10/30": "Today is the day when most movies about Halloween tend to begin.",
  "10/31": "Today, you could straight up go into work dressed as Pikachu and no one will bat an eye.",
  "11/1": "Today, if you go into work dressed as Pikachu, people will ask questions. And not good ones.",
  "11/2":
    "Día de los Muertos, is a traditional Mexican holiday celebrated on November 2. It ends Halloween season. Bye, Halloween.",
  "11/3": "Happy 58-days-to-go-before-the-end-of-the-year day!",
  "11/4": "Today is Common Sense Day. Use it or lose it, guys.",
  "11/5": "Remember, remember, the November 5th. Wait, I think I messed that up.",
  "11/6": "Happy Birthday, Charles II, the last and possibly ugliest monarch of the Spanish Habsburg dynasty.",
  "11/7": "Happy Birthday Marie Curie. And Happy Birthday to Lorde, the Marie Curie of pop music, as only I call her.",
  "11/8": "Happy Birthday to everyone born on this day. And to no one born yesterday or tomorrow.",
  "11/9": "November 9 is also a book written by Colleen Hoover. It's got a 4.3/5 on Goodreads. Not bad, Colleen.",
  "11/10": "Today is Heroes Day in Indonesia. You're my hero. Everyday.",
  "11/11": "It's 11/11. At 11 past 11 in the morning, it will be 11:11 11/11. Which is far too many 1's.",
  "11/12":
    "It's Ryan Gosling's Birthday. But, if you're Ryan Gosling, I bet everyday kinda feels like it's your birthday.",
  "11/13": "Only 48 days remaining until the end of the year. And only one day remaining until Gary Vee's birthday.",
  "11/14": "Happy Birthday Gary Vee! [insert quirky joke only Gary Vee will get/appreciate]",
  "11/15":
    "On this day at the Battle of the Winwaed: Penda of Mercia is defeated by Oswiu of Bernicia. Who could forget that?!",
  "11/16": "Every year, on this day, the world celebrates November 16th.",
  "11/17": "Happy Birthday to the comedy god, Danny Devito.",
  "11/18": "November 18th is also a song by Drake. If you were born on this day, he wrote it for and about you.",
  "11/19":
    "People born today are on the Scorpio-Sagittarius Astrological Cusp. So they get to choose either sign. Whatever makes them feel better.",
  "11/20": "Today, in the past, Future was born. Trippy.",
  "11/21": "Happy Birthday Davido, Fairuz and Bjork. If you have two names, GTFO.",
  "11/22": "On this day, Nominoe defeats Frankish King, Charles the Bald. A nickname he probably did not give himself.",
  "11/23":
    "Fibonacci Day takes place every year on November 23rd, because 11/23 is the first 4 digits of the Fibonacci sequence. ::The More You Know::",
  "11/24": "It's Sagittarius season. The hardest sign to spell, but one of the easiest to get along with.",
  "11/25":
    "One month until Christmas. Yet, two months after you first started hearing Christmas songs in every store you go into.",
  "11/26": "It's probably Thanksgiving in America. Happy Thanksgiving, America.",
  "11/27": "Happy Birthdays to guitar god, Jimi Hendrix and martial arts god, Bruce Lee.",
  "11/28":
    "People born on this day have an amicable character and do not let themselves down with any hardship. Everyone should be more like them.",
  "11/29":
    "According to the zodiac, people born today want to absolutely rage all day, every day. At least, that's how I read it.",
  "11/30": "Hey, everyone. It's Steve Aoki Day!",
  "12/1": "Guess who else was born today? Yep, Mihály Vörösmarty, our *favorite* Hungarian poet.",
  "12/2":
    "The 336th day of the year (337th in leap years) in the Gregorian calendar. Not using the Gregorian? Get with it!",
  "12/3": 'In 2021, your birthday is a Friday. Fri-Yay! Yes, that\'s a portmanteau of "Friday" and "Yay".',
  "12/4": "Today is Jay-Z's birthday. Hopefully, today he has <98 problems.",
  "12/5": "It's starting to get cold in the Northern Hemisphere, isn't it? You should put a coat on.",
  "12/6": "Happy Birthday, Giannis. Our favorite Antetokounmpo bro.",
  "12/7":
    "In 1354, Margaretha van Bavarian's son earl Willem V signed a peace treaty. Hopefully it still holds. We love peace.",
  "12/8": "Happy Birthday Nicki Minaj. Drop the album, Nicki. Drop it.",
  "12/9": "Only 22 days left in the year. But, on the plus side, there's still 387 left in next year.",
  "12/10": "It's Human Rights Day. Shouldn't every day be Human Rights Day though, really?",
  "12/11": "Happy Birthday Rey Mysterio. Have a day, luchadors!",
  "12/12": "In less than 100 years, it will be 12.12.12. We can't wait!",
  "12/13":
    "It's Taylor Swift's birthday. Which makes it the most important day of the year to a lot of young girls and boys.",
  "12/14": "If you're born on December 14th, you are naturally charismatic and unafraid of taking risks. Go you.",
  "12/15":
    "If you're born today, you're most compatible with people born under Aries or Leo. Go find them. Or don't. Up to you.",
  "12/16":
    "An Lushan revolts against Chancellor Yang Guozhong at Fanyang, initiating the An Shi Rebellion. Yep, that was today.",
  "12/17": "Happy Ugly Sweater Day, to all the ugly sweaters out there.",
  "12/18": "Happy Birthday Brad Pitt, you smokeshow, you.",
  "12/19":
    "If you were born on this day, your charm and showmanship are amongst your most defining qualities. You would've been a great medieval knight.",
  "12/20": "Today is Jonah Hill's birthday and JoJo's birthday. A very Jo Birthday day.",
  "12/21":
    "Today is the shortest day of the year in the northern hemisphere. But it's still listed as 5'10 on it's dating profiles.",
  "12/22": "Today is almost, but-not-quite Christmas. People have already stopped working for the year.",
  "12/23": "Today, no work is getting done in any office on the entirety of the planet. Guaranteed.",
  "12/24": "Today, a lot of people remember how annoying it is to have dinner with their family. Others enjoy it.",
  "12/25": "Happy Birthday to tons of people named Chris and Christian and Christina and Christiana.",
  "12/26": "Happy Boxing Day to those who celebrate. And to boxers, who are a very adorable breed of dog.",
  "12/27":
    "Now is the time of the year where you just sit around eat cookies at random intervals throughout the day. Not bad.",
  "12/28": "Three days remaining until the end of the year. But  who's counting? Me. I'm counting.",
  "12/29": "Happy Birthday, Jude Law. If anybody knows Jude, tell him we said Happy Birthday.",
  "12/30": "Just one more day until the worst party night of the year!",
  "12/31": "If you've got a resolution, you've got until midnight to keep doing whatever it is.",
};
