import * as React from "react";
import { Link } from "react-router-dom";

const Modal = (props) => {
  const { isOpen, title } = props;

  if (!isOpen) {
    return <></>;
  }
  return (
    <div className="modal-container" onClick={props.onClose}>
      <div className="modal" onClick={(event) => event.stopPropagation()}>
        <div className="modal__header">
          <h3>{title}</h3>
        </div>
        <div className="modal__container">{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
