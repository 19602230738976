import * as React from "react";

const WalletHelp = () => {
  return (
    <>
      <p className="text-sm">We recommend two different wallets. </p>
      <ol className="text-sm list" style={{ listStyle: "circle" }}>
        <li>
          <strong className="bold">MetaMask</strong> - The most popular and robust wallet and default for web3
        </li>
        <li>
          <strong className="bold">Fortmatic</strong> - Better for those who want easy email and password signup
        </li>
      </ol>

      <p className="text-sm mt-3 bold">MetaMask</p>
      <ol className="text-sm list" style={{ listStyle: "number" }}>
        <li>
          Go to{" "}
          <a className="bold" href="https://metamask.io/" target="_blank">
            MetaMask.io
          </a>{" "}
          and download the browser extension
        </li>
        <li>Follow the instructions on MetaMask.io to set up your wallet</li>
        <li>Return to the Claim NFT page (you can always get to it by using the link in the email you received)</li>
        <li>Click "I have a wallet" on the Claim NFT page and follow the instructions to connect it</li>
      </ol>

      <p className="text-sm mt-3 bold">Fortmatic</p>
      <ol className="text-sm list" style={{ listStyle: "number" }}>
        <li>Click “I have a Wallet” on this page</li>
        <li>Click the Fortmatic logo</li>
        <li>Enter your email address or phone number</li>
        <li>Enter the 6 digit code sent to you</li>
        <li>Enter a password</li>
        <li>That’s it! You’re done!</li>
      </ol>
    </>
  );
};

export default WalletHelp;
