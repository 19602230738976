import * as React from "react";
import { Link } from "react-router-dom";

import useWindowSize from "../utils/use-window-size-hook";
import Menu from "./menu";
import WalletMenu from "./wallet-menu";

const GlobalNav = () => {
  return (
    <div className="GlobalNav">
      <Link to="/" className="GlobalNav_Logo">
        HBD NFT
      </Link>
      {
        <ul className="GlobalNav_Items">
          <li className="GlobalNav_HiddenWide mr-2">
            <Menu title="Menu" xsAlign="left">
              <ul>
                <li>
                  <Link to={"/how-it-works"}>How It Works</Link>
                </li>
                <li>
                  <Link to={"/wallet-help"}>Wallet Help</Link>
                </li>
                <li>
                  <Link to={"/about"}>About</Link>
                </li>
              </ul>
            </Menu>
          </li>
          <li className="GlobalNav_HiddenNarrow mr-4">
            <Link to={"/how-it-works"}>How It Works</Link>
          </li>
          <li className="GlobalNav_HiddenNarrow mr-4">
            <Link to={"/wallet-help"}>Wallet Help</Link>
          </li>
          <li className="GlobalNav_HiddenNarrow mr-4">
            <Link to={"/about"}>About</Link>
          </li>
          <li>
            <WalletMenu />
          </li>
        </ul>
      }
    </div>
  );
};

export default GlobalNav;
