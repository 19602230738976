import * as React from "react";

import NftThumbnail from "./nft-thumbnail";

const NftColumnView = (props) => {
  const { nftEdition, hideOwnership } = props;

  return (
    <div className="NftColumnView">
      <div className="NftColumnView_image">
        <NftThumbnail nftEdition={nftEdition} className="NftThumbnail_shadow" hideOwnership={hideOwnership} />
      </div>
      <div className="NftColumnView_content">{props.children}</div>
    </div>
  );
};

export default NftColumnView;
