import getWeb3Modal from "./web3modal";
import { changeProviderChain, changeAccountAddress } from "../store/slices/session";

const providersPromiseMap = {};

const metaMaskProviderSetup = (provider, thunkAPI) => {
  const chainChangeHandler = (chainId) => {
    thunkAPI.dispatch(changeProviderChain(chainId));
  };
  const accountChangeHandler = (accountAddress) => {
    const newAccountAddress = accountAddress[0];
    if (accountAddress) {
      thunkAPI.dispatch(changeAccountAddress(newAccountAddress));
    } else {
      thunkAPI.dispatch(destroySession());
    }
  };

  provider.on("chainChanged", chainChangeHandler);
  provider.on("accountsChanged", accountChangeHandler);

  thunkAPI.dispatch(changeProviderChain(provider.chainId));

  return () => {
    provider.removeListener("chainChanged", chainChangeHandler);
    provider.removeListener("accountsChanged", accountChangeHandler);
  };
};

const nullProviderSetup = () => {
  return () => {};
};

const setupProvider = async (providerPromise, thunkAPI) => {
  const provider = await providerPromise;
  if (provider.isMetaMask) {
    provider.teardown = metaMaskProviderSetup(provider, thunkAPI);
  } else {
    provider.teardown = nullProviderSetup();
  }
  return provider;
};

const teardownProvider = async (providerId) => {
  const providerPromise = providersPromiseMap[providerId];
  if (!providerPromise) {
    return;
  }
  const provider = await providerPromise;
  provider.teardown();
};

const createWeb3Provider = async (providerId, thunkAPI) => {
  if (!providerId) {
    return null;
  }
  try {
    var providerPromise;
    if (providersPromiseMap[providerId]) {
      providerPromise = providersPromiseMap[providerId];
    } else {
      const { network, infuraId, fortmaticKey } = thunkAPI.getState().config;
      const web3Modal = getWeb3Modal({ network, infuraId, fortmaticKey });
      providerPromise = web3Modal.connectTo(providerId);
      providersPromiseMap[providerId] = providerPromise;
      web3Modal.clearCachedProvider();
      setupProvider(providerPromise, thunkAPI);
    }
    return await providerPromise;
  } catch (e) {
    // when the provider fails to connect is probably because we've lost the session or the user
    // has revoked access to their account. In either case, we want to destroy the session
    thunkAPI.dispatch(destroySession());
    throw e;
  }
};

const destroyWeb3Provider = (providerId, thunkAPI) => {
  teardownProvider(providerId);
  providersPromiseMap[providerId] = null;
};

export { createWeb3Provider, destroyWeb3Provider };
