import React, { useEffect, useState } from "react";

const NftEditionDetails = (props) => {
  const { nftEdition, defaultExpanded } = props;
  const [showDetails, setShowDetails] = useState(!!defaultExpanded);
  const [metadata, setMetadata] = useState({});

  const getMetadata = () => {
    fetch(nftEdition.metadata_url)
      .then((response) => response.json())
      .then((metadataJson) => setMetadata(metadataJson));
  };

  useEffect(() => {
    getMetadata();
  }, []);

  const getMetadataHtml = (attribute, i) => {
    return (
      <div className="data-box mb-1" key={i}>
        <p className="key">{attribute.trait_type}</p>
        <p className="value">{attribute.value}</p>
      </div>
    );
  };

  return (
    <>
      <div className="data-row bold mb-1">
        <p>Details</p>
      </div>
      <div className={`data-box-container ${!showDetails && "data-box-container--collapsed"}`}>
        <div className="data-box mb-1">
          <p className="key">Token ID</p>
          <p className="value">{nftEdition.token_id}</p>
        </div>
        <div className="data-box mb-1">
          <p className="key">Blockchain</p>
          <p className="value">Ethereum</p>
        </div>
        {metadata && metadata.attributes && metadata.attributes.map((attribute, i) => getMetadataHtml(attribute, i))}
      </div>
      <div onClick={() => setShowDetails(!showDetails)} className="data-box-toggle">
        {showDetails && "Show less"}
        {!showDetails && "Show more"}
      </div>
    </>
  );
};

export default NftEditionDetails;
