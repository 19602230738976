import * as React from "react";
import { Link } from "react-router-dom";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
};

const paragraphStyles = {
  marginBottom: 48,
};

// markup
const NotFoundPage = () => {
  React.useEffect(() => {
    document.title = "Page Not Found";
  }, []);
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
      </p>
      <p>
        <Link style={{ color: "#df1c9d" }} to="/">
          Go home
        </Link>
        .
      </p>
    </main>
  );
};

export default NotFoundPage;
