import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { destroySession } from "../store/slices/session";

const baseQuery = fetchBaseQuery({
  baseUrl: "/api/v1/",
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = getState().session.authorization;
    if (token) {
      headers.set("X-Ethereum-Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithSessionMgmt = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    api.dispatch(destroySession());
  }
  return result;
};

// Define a service using a base URL and expected endpoints
export const nftHbdApi = createApi({
  reducerPath: "nftHbd",
  baseQuery: baseQueryWithSessionMgmt,
  tagTypes: ["NftEdition", "Reminder", "Claim", "UNAUTHORIZED", "UNKNOWN_ERROR"],
  endpoints: (builder) => ({
    getNftEdition: builder.query({
      query: (tokenId) => `nft_editions/${tokenId}`,
    }),
    getNftEditions: builder.query({
      query: ({ monthIndex, dayIndex, onlyAvailable }) => {
        let qp = [];
        if (monthIndex) qp.push(`month_index=${monthIndex}`);
        if (dayIndex) qp.push(`day_index=${dayIndex}`);
        if (onlyAvailable) qp.push(`only_available=${onlyAvailable}`);
        return `nft_editions?${qp.join("&")}`;
      },
      providesTags: ["NftEdition"],
    }),
    getNftEditionCounts: builder.query({
      query: ({ monthIndex, dayIndex, onlyAvailable }) => {
        let qp = [];
        if (monthIndex) qp.push(`month_index=${monthIndex}`);
        if (dayIndex) qp.push(`day_index=${dayIndex}`);
        if (onlyAvailable) qp.push(`only_available=${onlyAvailable}`);
        return `nft_editions/counts?${qp.join("&")}`;
      },
    }),
    getClaimNftEdition: builder.query({
      query: ({ tokenId, nftClaimToken }) => `nft_editions/${tokenId}/claim?nft_claim_token=${nftClaimToken}`,
      providesTags: (result, error, tokenId) => [{ type: "Claim", tokenId }],
    }),
    getOrder: builder.query({
      query: ({ orderId, accessToken }) => `orders/${orderId}?access_token=${accessToken}`,
    }),
    getReminder: builder.query({
      query: (tokenId) => `nft_editions/${tokenId}/reminder`,
      providesTags: (result, error, tokenId) =>
        result ? [{ type: "Reminder", tokenId }] : error?.status === 401 ? ["UNAUTHORIZED"] : ["UNKNOWN_ERROR"],
    }),
    getBatchOrdersAndNftEditions: builder.query({
      query: (tokenIds) => `orders?token_ids=${tokenIds.join(",")}`,
    }),
    createSessionNonce: builder.mutation({
      query(account_address) {
        return { url: "session/nonce", method: "POST", body: { account_address } };
      },
    }),
    createSession: builder.mutation({
      query(signed_nonce) {
        return { url: "session", method: "POST", body: { signed_nonce } };
      },
    }),
    createOrder: builder.mutation({
      query(order) {
        return {
          url: "orders",
          method: "POST",
          body: order,
        };
      },
    }),
    createReminder: builder.mutation({
      query(body) {
        return {
          url: `nft_editions/${body.token_id}/reminder`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error, { token_id }) => [{ type: "Reminder", token_id }],
    }),
    createClaim: builder.mutation({
      query(body) {
        return {
          url: `nft_editions/${body.token_id}/claim`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error, { token_id }) => [{ type: "Claim", token_id }],
    }),
    createRecipientWallet: builder.mutation({
      query(body) {
        return {
          url: `orders/${body.order_id}/recipient_wallet`,
          method: "POST",
          body: body,
        };
      },
    }),
    refetchUnauthorizedQueries: builder.mutation({
      queryFn: () => ({ data: null }),
      invalidatesTags: ["UNAUTHORIZED"],
    }),
  }),
});

export const {
  useGetNftEditionQuery,
  useGetNftEditionsQuery,
  useGetNftEditionCountsQuery,
  useGetClaimNftEditionQuery,
  useGetOrderQuery,
  useGetReminderQuery,
  useGetBatchOrdersAndNftEditionsQuery,
  useCreateSessionNonceMutation,
  useCreateSessionMutation,
  useCreateOrderMutation,
  useCreateReminderMutation,
  useCreateClaimMutation,
  useCreateRecipientWalletMutation,
  useRefetchUnauthorizedQueriesMutation,
} = nftHbdApi;
