import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import amplitude from "amplitude-js";

const selectConfig = createSelector(
  (store) => store.config,
  (config) => config
);

export { selectConfig };

const CONFIG = JSON.parse(document.head.querySelector("meta[name='config']").content);

amplitude.getInstance().init(CONFIG.amplitudeKey);

const configSlice = createSlice({
  name: "config",
  initialState: CONFIG,
});

// Action creators are generated for each case reducer function
export const {} = configSlice.actions;

export default configSlice.reducer;

const useConfig = () => useSelector(selectConfig);
export { useConfig };
