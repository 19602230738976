import * as React from "react";
import "../style/main.scss";
import Page from "../components/page.jsx";
import GlobalNav from "../components/global-nav.jsx";
import MarketingHero from "../components/marketing-hero";
import SpaceBackground from "../components/space-background";
import WalletHelp from "../components/wallet-help";

const WalletHelpPage = () => {
  return (
    <Page className="legal" title="Legal">
      <SpaceBackground>
        <GlobalNav />
        <MarketingHero>
          <h1>Choosing Your Wallet</h1>
        </MarketingHero>
      </SpaceBackground>
      <div className="page-content">
        <WalletHelp />
      </div>
    </Page>
  );
};
export default WalletHelpPage;
