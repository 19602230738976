import * as React from "react";

const Menu = (props) => {
  const { children, title, xsAlign, onMenuStateChange } = props;
  const [isOpen, setIsOpen] = React.useState(false);

  function toggleOpen() {
    const newValue = !isOpen;
    setIsOpen(newValue);
    if (onMenuStateChange) {
      onMenuStateChange(newValue);
    }
  }

  const alignClass = "Menu_Dropdown--align-xs-" + (xsAlign ? xsAlign : "right");

  return (
    <div className={`Menu ${isOpen && "Menu--open"}`}>
      <a onClick={toggleOpen} className="Menu_Button">
        <span className="text-sm bold">{title}</span>
      </a>
      <div className="Menu_Backdrop" onClick={toggleOpen}></div>
      <div className={`Menu_Dropdown ${alignClass}`}>{children}</div>
    </div>
  );
};

export default Menu;
