import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import amplitude from "amplitude-js";

import "../style/main.scss";
import Page from "../components/page";
import Modal from "../components/modal";
import GlobalNav from "../components/global-nav";
import MarketingHero from "../components/marketing-hero";
import SpaceBackground from "../components/space-background";
import NftColumnView from "../components/nft-column-view";
import WalletHelp from "../components/wallet-help";

import { useCreateClaimMutation, useGetClaimNftEditionQuery } from "../services/nft-hbd";
import { connectWallet, selectSession } from "../store/slices/session";

const ClaimNftPage = ({ match }) => {
  const { tokenId, nftClaimToken } = match.params;
  const [createClaim] = useCreateClaimMutation();
  const { data, error, isLoading } = useGetClaimNftEditionQuery({ tokenId, nftClaimToken });
  const nftEdition = data?.order?.nft_edition;
  const order = data?.order;
  const isClaimed = order?.status != "pending_claim";

  const dispatch = useDispatch();
  const { isWalletConnected, accountAddress } = useSelector(selectSession);

  const claimNftWithWallet = async () => {
    var accountAddressParam = accountAddress;
    if (!isWalletConnected) {
      const res = await dispatch(connectWallet());
      accountAddressParam = res.payload?.accountAddress;
    }
    if (accountAddressParam) {
      const { error } = await createClaim({
        token_id: tokenId,
        nft_claim_token: nftClaimToken,
        claim: {
          recipient_wallet_address: accountAddressParam,
        },
      });
      if (error) {
        alert(error.data.error);
        amplitude.getInstance().logEvent("ClaimNftFailed", { error: error.data.error, tokenId });
      } else {
        amplitude.getInstance().logEvent("ClaimNftCompleted", { tokenId });
      }
    }
  };

  const [isSetupWalletModalOpen, setSetupWalletModalOpen] = React.useState(false);

  return (
    <Page title="Claim your gifted NFT">
      <SpaceBackground>
        <GlobalNav />
        <MarketingHero>
          {isLoading ? (
            <h1 className="text-xl">Loading...</h1>
          ) : error ? (
            <h1 className="text-xl">Error loading card</h1>
          ) : (
            <>
              <p className="text-sm">{order.nft_edition.title}</p>
              <h1 className="text-xl">Claim Your NFT, {order.recipient_name}!</h1>
            </>
          )}
        </MarketingHero>
      </SpaceBackground>
      {!isLoading && !error && (
        <NftColumnView nftEdition={nftEdition} hideOwnership={true}>
          {isClaimed ? (
            <>
              <h2 className="text-lg bold mb-3">You’re all set!</h2>
              <p className="text-sm mb-3">
                You’ve connected your wallet and you’re all ready to receive your NFT. We’ll now send an email to{" "}
                {order.buyer_name} letting them know they can now transfer you the NFT.
              </p>
              <p className="text-sm mb-3">
                Once they do, we’ll send you an email with a link to accept it. Happy birthday!
              </p>
            </>
          ) : (
            <>
              <p className="text-lg mb-3 bold">
                It's time to claim the NFT {order.buyer_name} sent you for your birthday!
              </p>
              <p className="text-sm mb-6 bold">
                If you have a wallet already, we’ll get it verified and then {order.buyer_name} can send you the NFT. If
                not, we’ll walk you through the steps to create one.
              </p>
              <a href="#" className="button-lg mt-6" onClick={claimNftWithWallet}>
                {isWalletConnected
                  ? `Use my Wallet ${accountAddress.substr(0, 5) + "..." + accountAddress.substr(-5)}`
                  : "I Have a Wallet"}
              </a>
              <a href="#" className="button-lg mt-6" onClick={() => setSetupWalletModalOpen(true)}>
                I Don't Have a Wallet
              </a>
            </>
          )}
        </NftColumnView>
      )}

      <Modal
        title="Need help getting set up? Look no further!"
        isOpen={isSetupWalletModalOpen}
        onClose={() => setSetupWalletModalOpen(false)}
        className="text-sm"
      >
        <WalletHelp />

        <p className="text-sm mt-3">Once you have set either one up, close this message and click “I have a wallet”</p>

        <input type="button" className="button-lg mt-3" value="Close" onClick={() => setSetupWalletModalOpen(false)} />
      </Modal>
    </Page>
  );
};
export default ClaimNftPage;
