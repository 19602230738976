import * as React from "react";
import "../style/main.scss";
import Page from "../components/page.jsx";
import GlobalNav from "../components/global-nav.jsx";
import MarketingHero from "../components/marketing-hero";
import SpaceBackground from "../components/space-background";

const LegalPage = () => {
  return (
    <Page className="legal" title="Legal">
      <SpaceBackground>
        <GlobalNav />
        <MarketingHero>
          <h1>Legal</h1>
        </MarketingHero>
      </SpaceBackground>
      <div className="page-content">
        <p className="text-sm mt-2">
          HBD NFT is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an
          interface allowing participants to exchange digital collectibles. Users are entirely responsible for the
          safety and management of their own private Ethereum wallets and validating all transactions and contracts
          generated by this website before approval. Furthermore, as the HBD NFT smart contract runs on the Ethereum
          network, there is no ability to undo, reverse, or restore any transactions.
        </p>

        <p className="text-sm mt-2">
          This website and its connected services are provided “as is” and “as available” without warranty of any kind.
          By using this website you are accepting sole responsibility for any and all transactions involving HBD NFT
          digital collectibles.
        </p>
        <div className="mt-6">
          <p className="text-lg mb-6 bold">OWNERSHIP</p>
          <p className="text-sm mt-2">
            i. You own the NFT. Each Piñata is an NFT on the Ethereum blockchain. When you purchase an NFT, you own the
            underlying Piñata, the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract and
            the Ethereum Network: at no point may we seize, freeze, or otherwise modify the ownership of any Piñata.
          </p>

          <p className="text-sm mt-2">
            ii. Personal Use. Subject to your continued compliance with these Terms, Sea Ranch Labs, Inc. grants you a
            worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that
            you choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial
            use; (ii) as part of a marketplace that permits the purchase and sale of your HBD NFT, provided that the
            marketplace cryptographically verifies each Piñata owner’s rights to display the Art for their Piñata to
            ensure that only the actual owner can display the Art; or (iii) as part of a third party website or
            application that permits the inclusion, involvement, or participation of your Piñata, provided that the
            website/application cryptographically verifies each Piñata owner’s rights to display the Art for their
            Piñata to ensure that only the actual owner can display the Art, and provided that the Art is no longer
            visible once the owner of the Piñata leaves the website/application.
          </p>

          <p className="text-sm mt-2">
            iii. Commercial Use. Subject to your continued compliance with these Terms, Sea Ranch Labs, Inc. grants you
            an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating
            derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be the
            use of the Art to produce and sell merchandise products (actual Piñatas etc.) based on designs of the Art.
            For the sake of clarity, nothing in this Section will be deemed to restrict you from (i) owning or operating
            a marketplace that permits the use and sale of HBD NFTs generally, provided that the marketplace
            cryptographically verifies each Piñata owner’s rights to display the Art for their Piñata to ensure that
            only the actual owner can display the Art; (ii) owning or operating a third party website or application
            that permits the inclusion, involvement, or participation of Piñatas generally, provided that the third
            party website or application cryptographically verifies each Piñata owner’s rights to display the Art for
            their Piñata to ensure that only the actual owner can display the Art, and provided that the Art is no
            longer visible once the owner of the Purchased Piñata leaves the website/application; or (iii) earning
            revenue from any of the foregoing.
          </p>
        </div>
      </div>
    </Page>
  );
};
export default LegalPage;
