import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * This is a utility that makes it easy for pages to scroll to the top when
 * they are routed to. Default React behavior is to stay at the same scroll
 * position when changing routes.
 *
 * See: https://reactrouter.com/web/guides/scroll-restoration
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
