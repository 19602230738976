import React from "react";

const CustomErrorAlertTemplate = ({ style, options, message, close }) => (
  <div
    style={{
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      pointerEvents: "all",

      backgroundColor: "#000",
      color: "white",

      borderRadius: "10px",
      padding: "16px",
      margin: "24px",

      fontSize: "16px",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#FF0040"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ marginRight: "20px", minWidth: "24px" }}
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="8" x2="12" y2="12"></line>
      <line x1="12" y1="16" x2="12" y2="16"></line>
    </svg>
    <span style={{ flex: "2 1 0%" }}>{message}</span>
    <button
      onClick={close}
      style={{
        marginLeft: "20px",
        border: "none",
        backgroundColor: "transparent",
        cursor: "pointer",
        color: "rgb(255, 255, 255)",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ marginRight: "0px", minWidth: "24px" }}
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </button>
  </div>
);

export default CustomErrorAlertTemplate;
