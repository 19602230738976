import * as React from "react";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";

import { useGetNftEditionCountsQuery } from "../services/nft-hbd";

import { months } from "../utils/months";

const SelectDayOfMonth = ({ name, numDays, monthIndex }) => {
  const {
    data: editionCountsData,
    error: editionCountsError,
    isFetching: editionCountsFetching,
  } = useGetNftEditionCountsQuery({ monthIndex, onlyAvailable: true });

  const days = Array.from(Array(numDays).keys()).map((i) => i + 1);
  const blankDays = Array.from(Array(7 - (numDays % 7)).keys()).map((i) => i + 1);
  const loadingDays = Array.from(Array(35).keys()).map((i) => i + 1);
  return (
    <div className="SelectDayOfMonth">
      <h3 className="SelectDayOfMonth_title">{name}</h3>
      <div className="SelectDayOfMonth_table">
        <>
          {editionCountsFetching ? (
            <>
              {loadingDays.map((d) => (
                <div className="SelectDayOfMonth_table_cell" key={"loading" + d}>
                  <div className="SelectDayOfMonth_table_cell_label">
                    <Loader type="ThreeDots" color="#888" height={40} width={40} />
                  </div>
                  <div className="SelectDayOfMonth_table_cell_text">&nbsp;</div>
                </div>
              ))}
            </>
          ) : (
            <>
              {days.map((dayIndex) => (
                <Link to={`date/${monthIndex}/${dayIndex}`} className="SelectDayOfMonth_table_cell" key={dayIndex}>
                  <div className="SelectDayOfMonth_table_cell_label">
                    <span className="SelectDayOfMonth_table_cell_label--wide-only">
                      {months[monthIndex - 1].name}&nbsp;
                    </span>
                    <span>{dayIndex}</span>
                  </div>
                  {editionCountsData[dayIndex] > 0 ? (
                    <div className="SelectDayOfMonth_table_cell_text">{editionCountsData[dayIndex]}&nbsp;left</div>
                  ) : (
                    <div className="SelectDayOfMonth_table_cell_text SelectDayOfMonth_table_cell_text--sold">
                      Sold&nbsp;out
                    </div>
                  )}
                </Link>
              ))}
              {blankDays.map((d) => (
                <div className="SelectDayOfMonth_table_cell SelectDayOfMonth_table_cell--blank" key={"blank" + d}></div>
              ))}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default SelectDayOfMonth;
