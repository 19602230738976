const months = [
  { name: "January", shortName: "Jan", numDays: 31, monthIndex: 1 },
  { name: "February", shortName: "Feb", numDays: 29, monthIndex: 2 },
  { name: "March", shortName: "Mar", numDays: 31, monthIndex: 3 },
  { name: "April", shortName: "Apr", numDays: 30, monthIndex: 4 },
  { name: "May", shortName: "May", numDays: 31, monthIndex: 5 },
  { name: "June", shortName: "Jun", numDays: 30, monthIndex: 6 },
  { name: "July", shortName: "Jul", numDays: 31, monthIndex: 7 },
  { name: "August", shortName: "Aug", numDays: 31, monthIndex: 8 },
  { name: "September", shortName: "Sep", numDays: 30, monthIndex: 9 },
  { name: "October", shortName: "Oct", numDays: 31, monthIndex: 10 },
  { name: "November", shortName: "Nov", numDays: 30, monthIndex: 11 },
  { name: "December", shortName: "Dec", numDays: 31, monthIndex: 12 },
];

export { months };
