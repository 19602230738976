import "../style/main.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import amplitude from "amplitude-js";
import { useAlert } from "react-alert";
import { useRollbar } from "@rollbar/react";

import GlobalNav from "../components/global-nav.jsx";
import Page from "../components/page.jsx";
import MarketingHero from "../components/marketing-hero";
import NftColumnView from "../components/nft-column-view";
import SpaceBackground from "../components/space-background";
import ReminderForm from "../components/reminder-form.jsx";
import { createBESession, selectSession } from "../store/slices/session";

import { useCreateReminderMutation, useGetNftEditionQuery, useGetReminderQuery } from "../services/nft-hbd";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CreateReminderPage = ({ match }) => {
  const { tokenId } = match.params;
  const {
    data: reminderData,
    error: reminderError,
    isFetching: reminderIsFetching,
    refetch: refetchReminder,
  } = useGetReminderQuery(tokenId);
  const [createReminder, { error: createReminderError, isLoading: isCreateReminderLoading }] =
    useCreateReminderMutation();
  const { data: nftEdition, error, isLoading } = useGetNftEditionQuery(tokenId);

  const dispatch = useDispatch();
  const { isBackendSession } = useSelector(selectSession);
  const isFromPurchase = useQuery().get("success") !== null;
  const alert = useAlert();
  const rollbar = useRollbar();

  const nextOccurrenceOfMonthAndDay = (month, day) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const occurrenceThisYear = new Date(now.getFullYear(), month - 1, day);
    const occurrenceNextYear = new Date(now.getFullYear() + 1, month - 1, day);

    // TODO: handle case where the month and day is TODAY,
    // we probably want to queue the reminder to send in +5 minutes or something
    const nextOccurrence = occurrenceThisYear > today ? occurrenceThisYear : occurrenceNextYear;

    return nextOccurrence;
  };

  const saveOrder = async ({
    buyer_name,
    buyer_email,
    subscribe_buyer,
    recipient_name,
    recipient_email,
    delivery_month,
    delivery_day,
    message,
    send_later,
  }) => {
    if (!isBackendSession) {
      await dispatch(createBESession());
    }

    if (isCreateReminderLoading) {
      return;
    }

    const notify_recipient_at = send_later
      ? nextOccurrenceOfMonthAndDay(delivery_month, delivery_day).toISOString()
      : null;
    return createReminder({
      token_id: tokenId,
      reminder: {
        buyer_name,
        buyer_email,
        subscribe_buyer,
        recipient_name,
        recipient_email,
        notify_recipient_at,
        message,
      },
    })
      .unwrap()
      .then((payload) => {
        amplitude.getInstance().logEvent("CreateReminderCompleted", { tokenId });
      })
      .catch((error) => {
        alert.show("The server encountered an error. Please try again later.");
        rollbar.error("Server error creating reminder", error, { tokenId });
        amplitude.getInstance().logEvent("CreateReminderFailed", { tokenId });
      })
      .finally(() => {
        refetchReminder();
      });
  };

  const hasReminderScheduled = () => {
    return reminderData && reminderData.order.status != "paid";
  };

  const getTitle = () => {
    if (doesNotOwnNft) {
      return "Looks like you don't own this NFT";
    } else if (hasReminderScheduled()) {
      return "Reminder Scheduled";
    } else if (isFromPurchase) {
      return "Success!";
    } else {
      return "Send To A Friend";
    }
  };

  const timingMessage = () => {
    if (reminderData.order.notify_recipient_at) {
      const prettyDate = new Date(Date.parse(reminderData.order.notify_recipient_at)).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
      return <>on {prettyDate}, we’ll send</>;
    } else {
      return "we've sent";
    }
  };

  // When we land in this page right after a successful mint it is possible that our
  // backend has not received the webhook call that lets us know the logged-in wallet
  // owns the nft. In that case, the GET reminder api would return a 404, but we don't
  // want to show "you don't own this" message.
  const doesNotOwnNft = !isFromPurchase && reminderError?.status == 404;
  const createErrorFromApi = createReminderError?.status == 422;

  return (
    <Page className="CreateReminder" title="Send as Gift">
      <SpaceBackground>
        <GlobalNav />
        <MarketingHero>
          {isLoading ? (
            <h1 className="text-xl">Loading...</h1>
          ) : error ? (
            <h1 className="text-xl">Error loading card</h1>
          ) : (
            <>
              <p className="text-sm">{nftEdition.title}</p>
              <h1 className="text-xl">{getTitle()}</h1>
            </>
          )}
        </MarketingHero>
      </SpaceBackground>
      {!isLoading && !error && (
        <NftColumnView nftEdition={nftEdition}>
          {doesNotOwnNft ? (
            <>
              <p className="text-sm mb-3">Please buy the NFT first before sending it to a friend!</p>
              <Link to={`/nft/${tokenId}`} className="button-lg">
                Go to Buy NFT
              </Link>
            </>
          ) : hasReminderScheduled() ? (
            <>
              <h2 className="text-lg bold mb-3">You’re all set!</h2>
              <p className="text-sm mb-3">
                To recap: {timingMessage()} {reminderData.order.recipient_email} an email letting them know you got them
                an NFT and giving them instructions on how to set up a wallet.
              </p>
              <p className="text-sm mb-3">
                Once they set up the wallet, we’ll send {reminderData.order.buyer_email} a link to a page where you can
                easily transfer the NFT to them. Happy Birthday sent! Heartfelt thanks and deep gratitude ensue!
              </p>
            </>
          ) : (
            <>
              <h2 className="text-lg bold mb-3">Success!</h2>
              <p className="text-sm mb-3">Someone’s Birthday just got Happier!</p>
              <p className="text-sm mb-3">
                Now, to make it easier: Fill in the fields below and we’ll let the recipient know what they’re getting,
                how they can get it, and who the absolute gem of a human it was who got it for them.
              </p>
              <ReminderForm
                submitAction={saveOrder}
                isFetchingReminder={reminderIsFetching}
                apiErrors={createErrorFromApi ? createReminderError.data : null}
              />
            </>
          )}
        </NftColumnView>
      )}
    </Page>
  );
};
export default CreateReminderPage;
