import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import "../style/main.scss";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";

import Page from "../components/page.jsx";
import GlobalNav from "../components/global-nav.jsx";
import Loader from "react-loader-spinner";
import HighlightBanner from "../components/highlight-banner";
import NftColumnView from "../components/nft-column-view";
import NftEditionDetails from "../components/nft-edition-details";
import MarketingHero from "../components/marketing-hero";
import SpaceBackground from "../components/space-background";
import useJokeByDay from "../utils/use-joke-by-day";
import useWindowSize from "../utils/use-window-size-hook";

import { useGetNftEditionQuery, useGetBatchOrdersAndNftEditionsQuery } from "../services/nft-hbd";
import { connectWallet, selectSession } from "../store/slices/session";
import {
  mintKey,
  mintNft,
  mintProgress,
  selectMintMap,
  selectTokenOwnership,
  selectIsCurrentSessionOwnerOfToken,
} from "../store/slices/eth";
import { selectConfig } from "../store/slices/config";
import { months } from "../utils/months";

const NftDetailPage = (props) => {
  const { tokenId } = props.match.params;
  const { data: nftEdition, error, isLoading } = useGetNftEditionQuery(tokenId);
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const { isWalletConnected, accountAddress } = useSelector(selectSession);
  const mintMap = useSelector(selectMintMap);
  const joke = useJokeByDay({ month: nftEdition?.month_index, day: nftEdition?.day_index });
  const tokenOwnership = useSelector(selectTokenOwnership);
  const isCurrentSessionOwnerOfToken = useSelector(selectIsCurrentSessionOwnerOfToken);

  const { width: windowWidth } = useWindowSize();
  const defaultExpanded = windowWidth > 1440;

  const thisMintKey = mintKey({
    accountAddress,
    price: nftEdition?.price_wei,
    tokenId: tokenId,
  });
  const mintTransaction = mintMap[thisMintKey];

  React.useEffect(() => {
    switch (mintTransaction?.status) {
      case "completed":
      case "receipt":
        history.push(`/nft/${tokenId}/create-reminder?success`);
        // we change the status of the minting so that if the user clicks back
        // they don't immediately get redirected to the create-reminder page again
        dispatch(
          mintProgress({
            args: {
              accountAddress,
              price: nftEdition?.price_wei,
              tokenId: tokenId,
            },
            status: "redirected",
          })
        );
        break;
      case "failed":
        const errorsToIgnore = [
          4001, // returned from metamask when the user rejects the transaction request
          -32603, // same, but for fortmatic... sigh
        ];
        if (errorsToIgnore.indexOf(mintTransaction.error?.code) >= 0) {
          return;
        }
        alert.show(mintTransaction.error?.message || "Error creating transaction");
        break;
    }
  }, [mintTransaction]);

  async function buyToken(tokenId, price) {
    var localAccountAddress = accountAddress;
    if (!isWalletConnected) {
      const res = await dispatch(connectWallet());
      if (!res.payload) {
        console.log("Unable to connect wallet");
        return;
      }
      localAccountAddress = res.payload.accountAddress;
    }

    dispatch(
      mintNft({
        accountAddress: localAccountAddress,
        price: price,
        tokenId: tokenId,
      })
    );
  }

  function getTransactionButtonContent() {
    switch (mintTransaction?.status) {
      case "sending":
      case "sent":
        return "Waiting For Signature";
      case "transactionHash":
      case "pending":
        return <Loader type="ThreeDots" color="#FFF" height={30} width={100} />;
      case "receipt":
      case "completed":
        return "Transfer Successful!";
      default:
        return "Buy Now";
    }
  }

  // use to check if the NFT can be sent to a friend
  const { data: batchResult } = useGetBatchOrdersAndNftEditionsQuery([tokenId], {
    // only fetch the order until the current wallet address owns the NFT
    // it won't exist before that
    skip: !isCurrentSessionOwnerOfToken?.[tokenId],
  });
  const orderInPaidState = batchResult?.orders?.[0]?.status == "paid";
  const thisTokenOwner = tokenOwnership?.[tokenId]?.status == "fulfilled" && tokenOwnership?.[tokenId]?.address;
  const isSold = thisTokenOwner || nftEdition?.known_minted_at;
  const isCurrentSessionOwnerAndInPaidState = isCurrentSessionOwnerOfToken?.[tokenId] && orderInPaidState;

  return (
    <Page title={`details for ${tokenId}`}>
      <SpaceBackground>
        <GlobalNav />
        <MarketingHero>
          {isLoading ? (
            <h1>Loading...</h1>
          ) : error ? (
            <h1>Error loading NFT</h1>
          ) : (
            <div className="header-flex">
              <h1>{nftEdition.title}</h1>
              <p>{joke}</p>
            </div>
          )}
        </MarketingHero>
      </SpaceBackground>
      {!config.mintingEnabled && <HighlightBanner>HBD piñatas haven't dropped yet. Check back soon!</HighlightBanner>}
      {!isLoading && !error && (
        <NftColumnView nftEdition={nftEdition}>
          {!isSold && <div className="text-lg mb-6 bold">Ξ{nftEdition.price_wei / 1e18}</div>}
          <NftEditionDetails nftEdition={nftEdition} defaultExpanded={defaultExpanded} />

          {!config.mintingEnabled ? (
            <div className="button-lg button--disabled mt-4">Available Soon</div>
          ) : isSold ? (
            isCurrentSessionOwnerAndInPaidState ? (
              <>
                <Link className="button-lg mt-4" to={`/nft/${tokenId}/create-reminder?success`}>
                  Send to a Friend
                </Link>
                <a className="button-lg button--secondary mt-2" target="_blank" href={nftEdition.opensea_url}>
                  View on OpenSea
                </a>
              </>
            ) : (
              <a className="button-lg button--secondary mt-4" target="_blank" href={nftEdition.opensea_url}>
                View on OpenSea
              </a>
            )
          ) : (
            <>
              <a className="button-lg mt-4 test" onClick={() => buyToken(tokenId, nftEdition.price_wei)}>
                {getTransactionButtonContent()}
              </a>
              <p className="text-sm ta-center mt-2">We’ll help you connect your wallet</p>
            </>
          )}
        </NftColumnView>
      )}
    </Page>
  );
};
export default NftDetailPage;
