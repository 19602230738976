import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import IndexPage from "../pages/index";
import { store } from "../store/index";

import { Provider as AlertProvider, positions, transitions, types } from "react-alert";
import BasicAlertTemplate from "react-alert-template-basic";
import CustomErrorAlertTemplate from "../components/custom-error-alert-template";

import { Provider as RollbackProvider, ErrorBoundary } from "@rollbar/react";

const alertOptions = {
  position: positions.TOP_CENTER,
  type: types.ERROR,
  transition: transitions.SCALE,
};

const rollbarConfig = {
  accessToken: store.getState().config.rollbarClientAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: store.getState().config.environment,
  },
};

const AlertTemplate = ({ style, options, message, close }) => {
  if (options.type == "error") {
    return <CustomErrorAlertTemplate {...{ style, options, message, close }} />;
  }
  return <BasicAlertTemplate {...{ style, options, message, close }} />;
};

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <Provider store={store}>
      <RollbackProvider config={rollbarConfig}>
        <ErrorBoundary>
          <AlertProvider template={AlertTemplate} {...alertOptions}>
            <IndexPage />
          </AlertProvider>
        </ErrorBoundary>
      </RollbackProvider>
    </Provider>,
    document.body.appendChild(document.createElement("div"))
  );
});
