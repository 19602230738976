import "../style/main.scss";

import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import NotFoundPage from "./404";

import Page from "../components/page.jsx";
import GlobalNav from "../components/global-nav.jsx";
import HighlightBanner from "../components/highlight-banner";
import MarketingHero from "../components/marketing-hero";
import NftThumbnail from "../components/nft-thumbnail";
import SpaceBackground from "../components/space-background";
import useJokeByDay from "../utils/use-joke-by-day";

import { months } from "../utils/months.jsx";
import { selectConfig } from "../store/slices/config";
import { selectTokenOwnership } from "../store/slices/eth";

import { useGetDynamicConfigQuery, useGetNftEditionsQuery } from "../services/nft-hbd";

const DatePage = (props) => {
  var { monthIndex, dayIndex } = props.match.params;
  monthIndex *= 1;
  dayIndex *= 1;
  const joke = useJokeByDay({ month: monthIndex, day: dayIndex });
  const {
    data: editionsData,
    error: editionsError,
    isLoading: editionsLoading,
  } = useGetNftEditionsQuery({ monthIndex, dayIndex });

  const tokenOwnership = useSelector(selectTokenOwnership);
  const config = useSelector(selectConfig);
  const datesMonth = months[monthIndex - 1];

  if (!datesMonth || isNaN(dayIndex) || datesMonth.numDays < dayIndex) {
    return <NotFoundPage />;
  }

  const nextMonth = (monthIndex % months.length) + 1;
  const nextDay = (dayIndex % datesMonth.numDays) + 1;
  const nextDayMonth = dayIndex + 1 <= datesMonth.numDays ? monthIndex : nextMonth;

  const prevMonth = monthIndex - 1 <= 0 ? months.length : monthIndex - 1;
  const prevDay = dayIndex - 1 <= 0 ? months[prevMonth - 1].numDays : dayIndex - 1;
  const prevDayMonth = dayIndex - 1 <= 0 ? prevMonth : monthIndex;

  return (
    <Page className="DatePage" title={`${datesMonth.name} ${dayIndex}`}>
      <SpaceBackground>
        <GlobalNav />
        <MarketingHero>
          <div className="header-flex">
            <h1>
              <Link to={`/date/${prevDayMonth}/${prevDay}`} className="icon">
                &larr;
              </Link>{" "}
              {`${datesMonth.name} ${dayIndex}`}{" "}
              <Link to={`/date/${nextDayMonth}/${nextDay}`} className="icon">
                &rarr;
              </Link>
            </h1>
            <p>{joke}</p>
          </div>
        </MarketingHero>
      </SpaceBackground>

      {!config.mintingEnabled && <HighlightBanner>HBD piñatas haven't dropped yet. Check back soon!</HighlightBanner>}

      <div className="page-content">
        <h3>
          <Link to={`/?month=${datesMonth.name}`} className="icon">
            &larr; Back
          </Link>
        </h3>
        <div className="DatePage_collection">
          {!editionsLoading &&
            editionsData.map((edition, i) => (
              <div key={`nft${i}`} className="DatePage_tile">
                <Link to={`/nft/${edition.token_id}`}>
                  <NftThumbnail nftEdition={edition} />
                </Link>
                <div className="DatePage_tile_caption">
                  <Link to={`/nft/${edition.token_id}`} className="DatePage_tile_info">
                    <div className="bold">
                      {datesMonth.name}&nbsp;{dayIndex}
                    </div>
                    <div>{edition.edition_number}&nbsp;of&nbsp;10</div>
                  </Link>
                  {edition.known_minted_at || tokenOwnership?.[edition.token_id]?.address ? (
                    <a
                      href={edition.opensea_url}
                      target="_blank"
                      className="DatePage_tile_action DatePage_tile_action--secondary"
                    >
                      <div className="bold">View on OpenSea</div>
                    </a>
                  ) : (
                    <Link
                      to={`/nft/${edition.token_id}`}
                      className="DatePage_tile_action DatePage_tile_action--primary"
                    >
                      <div className="bold">Buy</div>
                      <div>{edition.price_wei / 1e18}&nbsp;ETH</div>
                    </Link>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </Page>
  );
};
export default DatePage;
