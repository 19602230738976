import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

import { createBESession, selectSession, destroySession } from "../store/slices/session";
import { listOwnedNfts, selectOwnedNfts } from "../store/slices/eth";
import { useGetBatchOrdersAndNftEditionsQuery } from "../services/nft-hbd";

import Menu from "./menu";

const WalletMenu = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { accountAddress, isWalletConnected, isBackendSession } = useSelector(selectSession);
  const shortAccountAddress = accountAddress ? (
    <>
      {accountAddress.substr(0, 6)}&hellip;{accountAddress.substr(-4)}
    </>
  ) : (
    ""
  );
  const { status: statusOwnedNfts, list: tokenIdList } = useSelector(selectOwnedNfts);

  const {
    isLoading: isLoadingOrders,
    isSuccess,
    isError,
    data: data,
  } = useGetBatchOrdersAndNftEditionsQuery(tokenIdList, {
    skip: tokenIdList === undefined || tokenIdList?.length === 0,
  });

  var orders,
    nftEditions = [],
    ordersByNftEditionId;
  if (isSuccess) {
    ({ orders, nft_editions: nftEditions } = data);
    nftEditions = [...nftEditions].sort((a, b) => a.token_id - b.token_id);
    ordersByNftEditionId = orders.reduce((map, obj) => {
      map[obj.nft_edition_id] = obj;
      return map;
    }, {});
  }

  const menuStateChanged = (isOpen) => {
    if (isOpen) {
      dispatch(listOwnedNfts({ ownerAddress: accountAddress }));
    }
  };

  const doLogin = function () {
    dispatch(createBESession());
  };

  const doLogout = function () {
    dispatch(destroySession());
  };

  const navigateToPinata = function (tokenId) {
    history.push(`/nft/${tokenId}`);
  };

  const navigateToPinataReminder = function (e, tokenId) {
    history.push(`/nft/${tokenId}/create-reminder`);
    e.stopPropagation();
  };

  const showLoading = statusOwnedNfts == "pending" || isLoadingOrders;
  const showError = statusOwnedNfts == "failed" || isError;

  return (
    <>
      {isBackendSession ? (
        <Menu title="Wallet" onMenuStateChange={menuStateChanged}>
          <div className="WalletMenu__account mb-4">
            <span className="WalletMenu__account__address">{shortAccountAddress}</span>
            <span className="WalletMenu__account__logout" onClick={doLogout}>
              Log Out
            </span>
          </div>
          {showLoading ? (
            <div>
              <Loader type="ThreeDots" color="#888" height={40} width={40} className="WalletMenu__loading" />
            </div>
          ) : showError ? (
            <span>Failed to load your list of HBD NFTs</span>
          ) : (
            <>
              {nftEditions.length === 0 && <li className="WalletMenu__empty">You don’t own any piñatas yet.</li>}
              <ul>
                {nftEditions.map((nftEdition, i) => (
                  <li key={i} className="WalletMenu__item" onClick={() => navigateToPinata(nftEdition.token_id)}>
                    <img className="WalletMenu__image" src={nftEdition.thumbnail_url} />
                    <div className="WalletMenu__info">
                      <div className="WalletMenu__info__title">{nftEdition.title}</div>
                      {ordersByNftEditionId[nftEdition.id]?.status == "paid" && (
                        <div
                          className="WalletMenu__info__reminder"
                          onClick={(e) => navigateToPinataReminder(e, nftEdition.token_id)}
                        >
                          Send to friend
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Menu>
      ) : isWalletConnected ? (
        <a href="#" onClick={doLogin}>
          Link Your Wallet
        </a>
      ) : (
        <a href="#" onClick={doLogin}>
          Link Your Wallet
        </a>
      )}
    </>
  );
};

export default WalletMenu;
