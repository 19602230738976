import * as React from "react";
import "../style/main.scss";
import Page from "../components/page.jsx";
import GlobalNav from "../components/global-nav.jsx";
import MarketingHero from "../components/marketing-hero";
import SpaceBackground from "../components/space-background";
import About1 from "../images/about1.png";
import About2 from "../images/about2.png";

const AboutPage = () => {
  return (
    <Page className="about" title="About">
      <SpaceBackground>
        <GlobalNav />
        <MarketingHero>
          <h1>About</h1>
        </MarketingHero>
      </SpaceBackground>
      <div className="page-content">
        <div className="marketing-flex">
          <div className="marketing-flex__text">
            <p className="text-sm mt-2">
              We started this project because we’re obsessed with NFTs. And because of that obsession, our friends and
              loved ones grew worried about us. 😉
            </p>
            <p className="text-sm mt-2">
              So we built HBD NFT to help get all of our newbie fam/crew onboarded to the fun and excitement of NFTs and
              web3.
            </p>
            <p className="text-sm mt-2">
              And not just any NFTs. But NFTs that mean something to their owners, help talented artists get paid in
              perpetuity, and give back to charity.
            </p>
            <p className="text-sm mt-2">Thus, the Metaverse Gift Shop was born.</p>
          </div>
          <div className="marketing-flex__image">
            <img src={About1} />
          </div>
        </div>
        <div className="marketing-flex marketing-flex--reverse mt-6">
          <div className="marketing-flex__text">
            <p className="text-lg mb-6 bold">OUR VISION</p>
            <p className="text-sm mt-2">
              The Metaverse Gift Shop. A place where you could buy something meaningful for yourself or introduce a
              crypto-newbie to our crazy growing world with a thoughtful gift.
            </p>
            <p className="text-sm mt-2">
              Not just birthdays. Not just piñatas. But special, charming, beautifully rendered and one-of-a-kind NFTs
              for every occasion.
            </p>
          </div>
          <div className="marketing-flex__image">
            <img src={About2} />
          </div>
        </div>
        <div className="mt-6">
          <p className="text-lg mb-6 bold">ROADMAP</p>
          <p className="text-sm mt-2">
            We’re starting with birthdays because everyone’s got one. If you want to get in on the NFT action, you might
            as well start with the day you were born.
          </p>
          <p className="text-sm mt-2">
            When we sell out, we’ll donate 10% of our initial sale to{" "}
            <a href="https://www.givewell.org/maximum-impact-fund" target="_blank">
              GiveWell's Maximum Impact Fund
            </a>
            , and post confirmation to our{" "}
            <a href="https://twitter.com/hbdnft" target="_blank">
              Twitter account
            </a>
            .
          </p>
          <p className="text-sm mt-2">
            From there, we’re planning to expand the Gift Shop with the help of our community. Head over to the{" "}
            <a href="https://discord.gg/PJv9BvQUJD" target="_blank">
              Discord
            </a>
            , jump in and let us know your ideas.
          </p>
          <p className="text-sm mt-2">Blockchain weddings? Bar Mitzvah NFTs? Quinceañera tokens?</p>
          <p className="text-sm mt-2">
            In the future, life milestones should live on blockchains. And the mementos that mark every important event
            will always be there, inside your wallet.
          </p>
        </div>
        <div className="mt-6">
          <p className="text-lg mb-6 bold">THE TEAM</p>
          <p className="text-sm mt-2">
            HBD NFT is a creation of{" "}
            <a href="https://twitter.com/amber_baris" target="_blank">
              @amber_baris
            </a>
            , our incredible artist, along with{" "}
            <a href="https://twitter.com/beilbott" target="_blank">
              @beilbott
            </a>
            ,{" "}
            <a href="https://twitter.com/elias_bednar" target="_blank">
              @elias_bednar
            </a>
            ,{" "}
            <a href="https://twitter.com/haamjaams" target="_blank">
              @haamjaams
            </a>
            ,{" "}
            <a href="https://twitter.com/matthgt" target="_blank">
              @matthgt
            </a>
            ,{" "}
            <a href="https://twitter.com/mylarzebra" target="_blank">
              @mylarzebra
            </a>
            ,{" "}
            <a href="https://twitter.com/kmatthews812" target="_blank">
              @kmatthews812
            </a>
            , and{" "}
            <a href="https://twitter.com/natemodi" target="_blank">
              @natemodi
            </a>
            .
          </p>
        </div>
      </div>
    </Page>
  );
};
export default AboutPage;
