import * as React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import amplitude from "amplitude-js";

import Footer from "./footer";
import HighlightBanner from "./highlight-banner";

import { selectSessionChainId } from "../store/slices/session";
import { selectConfig } from "../store/slices/config";

const chainIdToName = {
  "0x1": "mainnet",
  "0x3": "ropsten",
  "0x4": "rinkeby",
  "0x5": "goerli",
  "0x6": "kotti",
};

const Page = (props) => {
  const routeMatch = useRouteMatch();
  const chainId = useSelector(selectSessionChainId);
  const { network } = useSelector(selectConfig);

  const pickedChainName = chainIdToName[chainId] || "wrong";
  const isWrongNetwork = chainId && pickedChainName !== network;

  React.useEffect(() => {
    document.title = `HBD NFT - ${props.title}`;
  }, [props.title]);

  React.useEffect(
    (match) => {
      amplitude.getInstance().logEvent("PageView", {
        path: routeMatch.path,
        url: routeMatch.url,
      });
    },
    [routeMatch]
  );

  return (
    <div className={`page ${props.className}`}>
      <div className="page__content">
        {isWrongNetwork && (
          <HighlightBanner>
            Your wallet is connected to the {pickedChainName} network. Please switch to {network}.
          </HighlightBanner>
        )}
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default Page;
