import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import amplitude from "amplitude-js";
import { connectWallet, createConnectedWeb3, selectSession } from "./session";
import hbdAbi from "../../contracts/hbd.js";

const selectEth = createSelector(
  (state) => state.eth,
  (eth) => eth
);
const selectTokenOwnership = createSelector(selectEth, (eth) => eth.tokenOwnership);
const selectTransfersMap = createSelector(selectEth, (eth) => eth.transfersMap);
const selectMintMap = createSelector(selectEth, (eth) => eth.mintMap);
const selectOwnedNfts = createSelector(selectEth, (eth) => eth.ownedNfts);

const selectIsCurrentSessionOwnerOfToken = createSelector(
  selectTokenOwnership,
  selectSession,
  (tokenOwnership, session) => {
    return Object.entries(tokenOwnership).reduce((result, [tokenId, { status, address }]) => {
      if (status == "fulfilled") {
        result[tokenId] = address == session.accountAddress;
      }
      return result;
    }, {});
  }
);

export {
  selectEth,
  selectTokenOwnership,
  selectTransfersMap,
  selectMintMap,
  selectOwnedNfts,
  selectIsCurrentSessionOwnerOfToken,
};

const getContract = async (thunkAPI) => {
  const { contractAddress, blockchainConfirmations } = thunkAPI.getState().config;

  await thunkAPI.dispatch(connectWallet());

  const providerId = thunkAPI.getState().session.providerId;
  const web3 = await createConnectedWeb3(providerId, thunkAPI);

  const res = new web3.eth.Contract(hbdAbi, contractAddress);
  res.transactionConfirmationBlocks = blockchainConfirmations;
  return res;
};

const ownerOf = createAsyncThunk("eth/ownerOf", async (tokenId, thunkAPI) => {
  try {
    const contract = await getContract(thunkAPI);
    const res = await contract.methods.ownerOf(tokenId).call();
    return thunkAPI.fulfillWithValue(res);
  } catch (e) {
    return thunkAPI.rejectWithValue("failed to check owner");
  }
});

const transferNft = createAsyncThunk("eth/transferNft", async (args, thunkAPI) => {
  const { tokenId, buyerWalletAddress, recipientWalletAddress } = args;
  try {
    const contract = await getContract(thunkAPI);
    amplitude.getInstance().logEvent("TransferNFtStarted", { tokenId, buyerWalletAddress, recipientWalletAddress });
    const res = await contract.methods
      .safeTransferFrom(buyerWalletAddress, recipientWalletAddress, tokenId)
      .send({ from: buyerWalletAddress })
      .on("sending", () => thunkAPI.dispatch(transferProgress({ args, status: "sending" })))
      .on("sent", () => thunkAPI.dispatch(transferProgress({ args, status: "sent" })))
      .on("transactionHash", () => thunkAPI.dispatch(transferProgress({ args, status: "transactionHash" })))
      .on("receipt", () => thunkAPI.dispatch(transferProgress({ args, status: "receipt" })));

    amplitude.getInstance().logEvent("TransferNFtCompleted", { tokenId, buyerWalletAddress, recipientWalletAddress });
    return thunkAPI.fulfillWithValue(res);
  } catch (e) {
    amplitude.getInstance().logEvent("TransferNFtFailed", { tokenId, buyerWalletAddress, recipientWalletAddress });
    return thunkAPI.rejectWithValue("failed to transfer");
  }
});

const mintNft = createAsyncThunk("eth/mintNft", async (args, thunkAPI) => {
  const { tokenId, accountAddress, price } = args;
  try {
    const contract = await getContract(thunkAPI);
    amplitude.getInstance().logEvent("MintNftStarted", { tokenId, accountAddress });
    const res = await contract.methods
      .mint(accountAddress, tokenId)
      .send({ value: price, from: accountAddress })
      .on("sending", () => thunkAPI.dispatch(mintProgress({ args, status: "sending" })))
      .on("sent", () => thunkAPI.dispatch(mintProgress({ args, status: "sent" })))
      .on("transactionHash", () => thunkAPI.dispatch(mintProgress({ args, status: "transactionHash" })))
      .on("receipt", () => thunkAPI.dispatch(mintProgress({ args, status: "receipt" })));
    amplitude.getInstance().logEvent("MintNftCompleted", { tokenId, accountAddress });
    return thunkAPI.fulfillWithValue(res);
  } catch (error) {
    var errorRes = { code: 0, message: "Transaction request rejected" };
    if (error?.receipt?.transactionHash) {
      try {
        // Cannot use native handleRevert because of the following bug:
        // https://github.com/ChainSafe/web3.js/issues/3742
        const providerId = thunkAPI.getState().session.providerId;
        const web3 = await createConnectedWeb3(providerId, thunkAPI);
        const tx = await web3.eth.getTransaction(error.receipt.transactionHash);
        const code = await web3.eth.call(tx);
      } catch (e) {
        const errorMessage = e.message.substring(e.message.indexOf("{"));
        const originalError = JSON.parse(errorMessage).originalError;
        errorRes = { code: originalError.code, message: originalError.message };
      }
    }
    if (error?.code) {
      errorRes = { code: error.code, message: error.message };
    }
    amplitude.getInstance().logEvent("MintNftFailed", { tokenId, accountAddress, errorReason: errorRes.message });
    return thunkAPI.rejectWithValue(errorRes);
  }
});

const listOwnedNfts = createAsyncThunk("eth/listOwnedNfts", async (args, thunkAPI) => {
  const { ownerAddress } = args;
  const contract = await getContract(thunkAPI);
  var ownedCount = 0;
  try {
    ownedCount = parseInt(await contract.methods.balanceOf(ownerAddress).call());
  } catch (err) {
    // for some reason, the web3 library raises an error when the balance is 0 :facepalm:
    return thunkAPI.fulfillWithValue([]);
  }

  try {
    const indexes = Array.from(Array(ownedCount).keys());
    const promises = indexes.map((i) => contract.methods.tokenOfOwnerByIndex(ownerAddress, i).call());
    const ownedNftStrings = await Promise.all(promises);
    const ownedNfts = ownedNftStrings.map((str) => parseInt(str)).sort((a, b) => a - b);
    return thunkAPI.fulfillWithValue(ownedNfts);
  } catch (err) {
    return thunkAPI.rejectWithValue(false);
  }
});

const transferProgress = createAction("eth/transferNft/progress");
const mintProgress = createAction("eth/mintNft/progress");

export { ownerOf, transferNft, mintNft, mintProgress, listOwnedNfts };

const transferKey = ({ tokenId, buyerWalletAddress, recipientWalletAddress }) => {
  return `${tokenId}:::${buyerWalletAddress?.toLowerCase()}:::${recipientWalletAddress?.toLowerCase()}`;
};

const mintKey = ({ tokenId, accountAddress, price }) => {
  return `${tokenId}:::${accountAddress?.toLowerCase()}:::${price}`;
};

export { transferKey, mintKey };

const ethSlice = createSlice({
  name: "eth",
  initialState: {
    tokenOwnership: {},
    transfersMap: {},
    mintMap: {},
    ownedNfts: [],
  },
  extraReducers: {
    [ownerOf.pending]: (state, action) => {
      return { ...state, tokenOwnership: { ...state.tokenOwnership, [action.meta.arg]: { status: "pending" } } };
    },
    [ownerOf.fulfilled]: (state, action) => {
      return {
        ...state,
        tokenOwnership: {
          ...state.tokenOwnership,
          [action.meta.arg]: { status: "fulfilled", address: action.payload.toLowerCase() },
        },
      };
    },
    [ownerOf.rejected]: (state, action) => {
      return { ...state, tokenOwnership: { ...state.tokenOwnership, [action.meta.arg]: { status: "failed" } } };
    },

    [transferNft.pending]: (state, action) => {
      return { ...state, transfersMap: { ...state.transfersMap, [transferKey(action.meta.arg)]: "pending" } };
    },
    [transferNft.fulfilled]: (state, action) => {
      return {
        ...state,
        transfersMap: { ...state.transfersMap, [transferKey(action.meta.arg)]: "completed" },
        tokenOwnership: {
          ...state.tokenOwnership,
          [action.meta.arg.tokenId]: {
            status: "fulfilled",
            address: action.meta.arg.recipientWalletAddress.toLowerCase(),
          },
        },
      };
    },
    [transferNft.rejected]: (state, action) => {
      return { ...state, transfersMap: { ...state.transfersMap, [transferKey(action.meta.arg)]: "failed" } };
    },
    [transferProgress]: (state, action) => {
      return {
        ...state,
        transfersMap: { ...state.transfersMap, [transferKey(action.payload.args)]: action.payload.status },
      };
    },

    [mintNft.pending]: (state, action) => {
      return { ...state, mintMap: { ...state.mintMap, [mintKey(action.meta.arg)]: { status: "pending" } } };
    },
    [mintNft.fulfilled]: (state, action) => {
      return {
        ...state,
        mintMap: { ...state.mintMap, [mintKey(action.meta.arg)]: { status: "completed" } },
        tokenOwnership: {
          ...state.tokenOwnership,
          [action.meta.arg.tokenId]: { status: "fulfilled", address: action.meta.arg.accountAddress.toLowerCase() },
        },
      };
    },
    [mintNft.rejected]: (state, action) => {
      return {
        ...state,
        mintMap: {
          ...state.mintMap,
          [mintKey(action.meta.arg)]: { status: "failed", error: action.payload },
        },
      };
    },
    [mintProgress]: (state, action) => {
      return {
        ...state,
        mintMap: { ...state.mintMap, [mintKey(action.payload.args)]: { status: action.payload.status } },
      };
    },

    [listOwnedNfts.pending]: (state, action) => {
      return { ...state, ownedNfts: { status: "pending" } };
    },
    [listOwnedNfts.fulfilled]: (state, action) => {
      return { ...state, ownedNfts: { status: "fulfilled", list: action.payload } };
    },
    [listOwnedNfts.rejected]: (state, action) => {
      return { ...state, ownedNfts: { status: "failed" } };
    },
  },
});

// Action creators are generated for each case reducer function
export const {} = ethSlice.actions;

export default ethSlice.reducer;
