import * as React from "react";

import { useDispatch, useSelector } from "react-redux";

import { selectSession } from "../store/slices/session";
import { ownerOf, selectTokenOwnership, selectIsCurrentSessionOwnerOfToken } from "../store/slices/eth";

const NftThumbnail = (props) => {
  const { nftEdition, hideOwnership } = props;

  const { isWalletConnected } = useSelector(selectSession);
  const tokenOwnership = useSelector(selectTokenOwnership);
  const isCurrentSessionOwnerOfToken = useSelector(selectIsCurrentSessionOwnerOfToken);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (isWalletConnected) {
      dispatch(ownerOf(nftEdition.token_id));
    }
  }, [nftEdition.token_id, isWalletConnected]);

  const thisTokenOwner =
    tokenOwnership?.[nftEdition.token_id]?.status == "fulfilled" && tokenOwnership?.[nftEdition.token_id]?.address;
  const isSold = thisTokenOwner || nftEdition?.known_minted_at;
  const thisTokenOwnedByCurrentSession = isCurrentSessionOwnerOfToken?.[nftEdition.token_id];

  return (
    <div className="NftThumbnail">
      <img src={nftEdition.thumbnail_url} />
      {!hideOwnership &&
        isSold &&
        (thisTokenOwnedByCurrentSession ? (
          <div className="NftThumbnail_inset NftThumbnail_inset--owned">You own this</div>
        ) : (
          <div className="NftThumbnail_inset NftThumbnail_inset--sold">Sold</div>
        ))}
    </div>
  );
};

export default NftThumbnail;
