import "../style/main.scss";
import * as React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AboutPage from "./about";
import HomePage from "./home";
import HowItWorksPage from "./how-it-works";
import LegalPage from "./legal";
import CreateReminderPage from "./create-reminder";
import ClaimNftPage from "./claim-nft";
import DatePage from "./date";
import NftDetailPage from "./nft-details";
import ScrollToTop from "../components/scroll-to-top";
import SendNftPage from "./send-nft";
import NotFoundPage from "./404";
import WalletHelpPage from "./wallet-help-page";

const IndexPage = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path={"/"} exact={true} component={HomePage} />
        <Route path={"/about"} exact={true} component={AboutPage} />
        <Route path={"/wallet-help"} exact={true} component={WalletHelpPage} />
        <Route path={"/claim-nft/:tokenId/:nftClaimToken"} exact={true} component={ClaimNftPage} />
        <Route path={"/date/:monthIndex/:dayIndex"} exact={true} component={DatePage} />
        <Route path={"/how-it-works"} exact={true} component={HowItWorksPage} />
        <Route path={"/legal"} exact={true} component={LegalPage} />
        <Route path={"/nft/:tokenId"} exact={true} component={NftDetailPage} />
        <Route path={"/nft/:tokenId/create-reminder"} exact={true} component={CreateReminderPage} />
        <Route path={"/send-nft/:orderId/:accessToken"} exact={true} component={SendNftPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
};
export default IndexPage;
