import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Web3 from "web3";
import amplitude from "amplitude-js";
import getWeb3Modal from "../../utils/web3modal";
import { nftHbdApi } from "../../services/nft-hbd";
import { createSelector } from "reselect";
import { createWeb3Provider, destroyWeb3Provider } from "../../utils/providers";

const selectSession = createSelector(
  (state) => state.session,
  (session) => session
);
const selectSessionProviderId = createSelector(selectSession, (session) => session.providerId);
const selectSessionChainId = createSelector(selectSession, (session) => session.providerChainId);

export { selectSessionChainId };

const createConnectedWeb3 = async (providerId, thunkAPI) => {
  if (!providerId) {
    return null;
  }
  const provider = await createWeb3Provider(providerId, thunkAPI);
  return new Web3(provider);
};

export { selectSession, createConnectedWeb3 };

const connectWallet = createAsyncThunk("session/connectWallet", async (_, thunkAPI) => {
  if (thunkAPI.getState().session.isWalletConnected) {
    const { accountAddress, providerId } = thunkAPI.getState().session;
    return thunkAPI.fulfillWithValue({ accountAddress, providerId });
  }

  const { network, infuraId, fortmaticKey } = thunkAPI.getState().config;
  const web3Modal = getWeb3Modal({ network, infuraId, fortmaticKey });

  var provider;
  var providerId;
  try {
    amplitude.getInstance().logEvent("WalletConnectStart");
    // The web3Modal API does not provide a way to retrieve the provider ID for the selected wallet
    // but they do provide a way to retrieve the cached provider. We don't want web3modal to do the
    // caching (we want to do that ourselves). We use that to get around the API's limitations by
    // asking to cache, getting the provider id and the clearing the cache.
    web3Modal.clearCachedProvider();
    provider = await web3Modal.connect();
    providerId = web3Modal.cachedProvider;
    web3Modal.clearCachedProvider();
    amplitude.getInstance().logEvent("WalletConnectProviderSelected", { providerId });
  } catch (error) {
    amplitude.getInstance().logEvent("WalletConnectFailed");
    web3Modal.clearCachedProvider();
    return thunkAPI.rejectWithValue("No provider selected or connection failed");
  }
  const web3 = new Web3(provider);
  const accounts = await web3.eth.getAccounts();
  if (!accounts) {
    return thunkAPI.rejectWithValue("No available accounts or failed to load accounts");
  }
  const accountAddress = accounts[0];
  amplitude.getInstance().logEvent("WalletConnected", { providerId, accountAddress });
  amplitude.getInstance().setUserId(accountAddress);
  amplitude.getInstance().setUserProperties({ providerId });
  return thunkAPI.fulfillWithValue({ web3, accountAddress, providerId });
});

const createBESession = createAsyncThunk("session/createBESession", async (_, thunkAPI) => {
  if (!thunkAPI.getState().session.isWalletConnected) {
    await thunkAPI.dispatch(connectWallet());
  }
  const { accountAddress, providerId } = thunkAPI.getState().session;
  const web3 = await createConnectedWeb3(providerId, thunkAPI);

  const nonceRes = await thunkAPI.dispatch(nftHbdApi.endpoints.createSessionNonce.initiate(accountAddress));
  const nonce = nonceRes.data.nonce;

  const signRes = await web3.eth.personal.sign(nonce, accountAddress);

  const sessionRes = await thunkAPI.dispatch(nftHbdApi.endpoints.createSession.initiate(signRes));
  if (sessionRes.data.success) {
    const res = thunkAPI.fulfillWithValue(sessionRes.data.success);
    thunkAPI.dispatch(nftHbdApi.endpoints.refetchUnauthorizedQueries.initiate(signRes));
    amplitude.getInstance().logEvent("BESessionCreated");
    return res;
  } else {
    amplitude.getInstance().logEvent("BESessionCreateFailed");
    return thunkAPI.rejectWithValue(false);
  }
});

const destroySession = createAsyncThunk("session/destroySession", async (_, thunkAPI) => {
  const { providerId } = thunkAPI.getState().session;
  destroyWeb3Provider(providerId);
  return thunkAPI.fulfillWithValue(true);
});

export { connectWallet, createBESession, destroySession };

const LOGGED_OUT_STATE = {
  isWalletConnected: false,
  isBackendSession: false,
  providerId: null,
  providerChainId: null,
  accountAddress: null,
  authorization: null,
};

const sessionSlice = createSlice({
  name: "session",
  initialState: LOGGED_OUT_STATE,
  reducers: {
    changeProviderChain(state, action) {
      return { ...state, providerChainId: action.payload };
    },
    changeAccountAddress(state, action) {
      return { ...state, accountAddress: action.payload };
    },
  },
  extraReducers: {
    [connectWallet.fulfilled]: (state, action) => {
      return {
        ...state,
        accountAddress: action.payload.accountAddress.toLowerCase(),
        providerId: action.payload.providerId,
        isWalletConnected: true,
      };
    },
    [connectWallet.rejected]: (state, action) => {
      return {
        ...state,
        accountAddress: null,
        isWalletConnected: false,
      };
    },

    [createBESession.rejected]: (state, action) => {
      return { ...state, isBackendSession: false };
    },
    [createBESession.fulfilled]: (state, action) => {
      return { ...state, isBackendSession: true, authorization: action.payload };
    },

    [destroySession.fulfilled]: (state) => {
      return { ...state, ...LOGGED_OUT_STATE };
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeAccountAddress, changeProviderChain } = sessionSlice.actions;

export default sessionSlice.reducer;
